.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.justify-center {
  justify-content: center;
}
.w-full {
  width: 100%;
}
.flex {
  display: flex;
}

html {
  background-color: #ffffff;
  scroll-behavior: smooth;
  font-size: 14px;
}

body {
  @apply md:overflow-x-hidden;
}

main {
  @apply mt-[56px] lg:mt-[70px];
  min-height: 0vh;
}
/*
    Sections define our stackable components! So, sections
      that are immediately under main (that are not marked as full-width)
      should have specific breakpoint settings for mobile, tablet,
      large tablet, and desktop.
  */
/*
    Then, any div that explicitly sets a content-width will also be content width
    (Only for full content width. This won't work for smaller grided content blocks.)
      Inside a full-width section (see below), this will make its child
       have proper content alignment at all breakpoints
  */
main .content-width:not(.content-width > .content-width) {
  @apply px-4 md:px-6 2xl:px-0 mx-auto lg:max-w-screen-lg xl:max-w-screen-xl;
}

/* Articles have a different width */
main > article .content-width:not(.content-width > .content-width) {
  @apply px-4 md:px-6 2xl:px-0 mx-auto lg:max-w-screen-lg;
}

/* We also have popout width a little wider */
main .popout-width:not(.popout-width > .popout-width) {
  @apply px-4 lg:px-0 mx-auto lg:max-w-screen-lg xl:max-w-screen-xl;
}

/*
    No matter where a full-width section falls, even nested in a content block,
      we want it to break out
  */
main .full-width {
  @apply relative -mx-[50vw] left-1/2 right-1/2 w-screen;

  @media only screen and (min-width: 2200px) {
    @apply mx-[-1100px] w-[2200px];
  }
}

/* Except on News, because of the sidebar */
main article.news .full-width {
  @apply w-full mx-0 left-0 right-0;
}

.bg-gray-50,
sonic-callout[theme="primary-light"],
sonic-callout[theme="tertiary-light"] {
  --link-color: var(--primary-750);
  --link-color-hover: var(--primary-800);
}

sonic-callout[theme="tertiary-light"] {
  --tertiary-800: #075c5c;
}

sonic-qdf {
  --disabled-field-color: var(--gray-500);
}

/* VOYAGER BUNDLE CSS */
._fieldGroup_1g3ja_1 {
  margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
  ._fieldGroup_1g3ja_1 {
    margin-left: auto;
    margin-right: auto;
    max-width: 24rem;
  }
}

._label_1g3ja_5 {
  margin-bottom: 0.25rem;
  display: block;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
}

._checkboxContainer_nvs1o_1 {
  display: flex;
}

._label_nvs1o_5 {
  margin-left: 0.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
}

._label_nvs1o_5 a {
  color: var(--primary-700, #533fbe);
}

._fieldGroup_fa1qv_1 {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

._field_fa1qv_1 {
  display: inline-flex;
  cursor: pointer;
  align-items: center;
  -moz-column-gap: 0.5rem;
  column-gap: 0.5rem;
  white-space: nowrap;
  border-radius: 0.25rem;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 0.5rem 1rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

@media (min-width: 768px) {
  ._field_fa1qv_1:hover {
    --tw-bg-opacity: 1;
    background-color: rgb(219 234 254 / var(--tw-bg-opacity));
  }
}

._pillGroup_fa1qv_8 {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  max-width: 32rem;
}

._pill_fa1qv_8 {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  display: inline-flex;
  align-items: center;
  -moz-column-gap: 0.5rem;
  column-gap: 0.5rem;
  border-radius: 0.75rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(129 140 248 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(238 242 255 / var(--tw-bg-opacity));
  padding: 0.5rem 1rem;
  font-weight: 700;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

._icon_fa1qv_16 {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(67 56 202 / var(--tw-text-opacity));
}

._reactSelect_fa1qv_20 {
  font-family: Arial;
  margin-left: auto;
  margin-right: auto;
  max-width: 32rem;
}

._reactSelect_fa1qv_20 [class$="-control"] {
  border-radius: 0.75rem;
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
  padding: 1rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

._reactSelect_fa1qv_20 [class$="-indicatorSeparator"] {
  display: none;
}

._infoPanel_zt3fc_1 {
  margin-left: auto;
  margin-right: auto;
  max-width: 32rem;
  cursor: pointer;
  border-radius: 0.5rem;
  background-color: var(--gray-50, #f9fafb);
  padding: 1rem 2rem;
  text-align: center;
}

._collapsed_zt3fc_5 {
  border-width: 2px;
  border-color: var(--primary-700, #533fbe);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

._collapsed_zt3fc_5 ._heading_zt3fc_8 > span {
  border-style: none;
}

._heading_zt3fc_8 {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
}

._heading_zt3fc_8 span {
  border-bottom-width: 2px;
  border-style: dashed;
  border-color: var(--gray-400, #9ca3af);
}

._heading_zt3fc_8 svg {
  margin-right: 0.75rem;
}

._content_zt3fc_24 {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

._radioField_6s6zg_1 {
  margin-bottom: 1.5rem;
  display: flex;
  width: 100%;
  max-width: none;
  justify-content: center;
}

._radioField_6s6zg_1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(7rem * var(--tw-space-x-reverse));
  margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)));
}

@media (min-width: 768px) {
  ._radioField_6s6zg_1 {
    margin-left: auto;
    margin-right: auto;
  }
}

._radioFieldHeading_6s6zg_4 {
  margin-bottom: 0.25rem;
  display: block;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

._radioOptions_6s6zg_8 {
  display: flex;
}

._radioOption_6s6zg_8 {
  margin-right: 0.5rem;
  accent-color: var(--primary-700, #533fbe);
}

._radioOption_6s6zg_8 label {
  margin-left: 0.25rem;
}

._container_vz0qt_1 {
  position: relative;
  margin: auto;
  height: 3.5rem;
  width: 20rem;
}

select._selectOverlay_vz0qt_5 {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
}

select._selectOverlay_vz0qt_5:focus-visible {
  border-color: var(--primary-700, #533fbe);
}

@media (min-width: 768px) {
  select._selectOverlay_vz0qt_5 {
    display: none;
  }
}

._dropDownContainer_vz0qt_9 {
  margin: auto;
  display: flex;
  height: 3.5rem;
  width: 20rem;
  align-items: center;
  border-radius: 9999px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--gray-300, #d1d5db);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

._dropDownContainer_vz0qt_9:hover {
  border-color: var(--gray-400, #9ca3af);
}

._activeDropDownContainer_vz0qt_13,
._activeDropDownContainer_vz0qt_13:hover {
  border-color: var(--primary-700, #533fbe);
}

._contentText_vz0qt_17 {
  width: 100%;
  padding-left: 1.5rem;
  text-align: left;
  color: var(--gray-400, #9ca3af);
}

._fieldInputText_vz0qt_21 {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

._iconContainer_vz0qt_25 {
  display: flex;
  height: 2.5rem;
  align-items: center;
  border-left-width: 1px;
  border-color: var(--gray-300, #d1d5db);
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

._chevronIcon_vz0qt_29 {
  color: var(--gray-300, #d1d5db);
}

._chevronIcon_vz0qt_29:hover {
  color: var(--gray-400, #9ca3af);
}

._chevronIcon_vz0qt_29:focus {
  color: var(--primary-700, #533fbe);
}

._activeChevronIcon_vz0qt_33 {
  color: var(--primary-700, #533fbe);
}

._dropDownOptionsContainer_vz0qt_37 {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  max-width: 20rem;
}

._dropDownOptionsWrapper_vz0qt_41 {
  position: absolute;
  margin: auto;
  display: none;
  max-height: 15rem;
  width: 100%;
  max-width: 20rem;
  overflow-x: hidden;
  border-radius: 0.375rem;
  border-width: 1px;
  border-color: var(--primary-100, #ddd9f2);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-drop-shadow: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07))
    drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}

._expanded_vz0qt_45 {
  display: block;
}

button._dropDownOption_vz0qt_37 {
  display: flex;
  height: 2.5rem;
  width: 20rem;
  max-width: 20rem;
  align-items: center;
  padding-left: 1.5rem;
}

button._dropDownOption_vz0qt_37:hover {
  background-color: var(--primary-200, #92b8e5);
}

button._optionSelected_vz0qt_53 {
  background-color: var(--primary-700, #533fbe);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

button._optionSelected_vz0qt_53:hover {
  background-color: var(--primary-700, #533fbe);
}

._textField_fwd9c_1 {
  width: 100%;
  border-radius: 9999px;
  border-width: 1px;
  border-style: solid;
  --tw-border-opacity: 1;
  border-color: rgb(228 228 231 / var(--tw-border-opacity));
  padding: 1rem 1.5rem;
}

._error_fwd9c_5 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

._fieldHeading_9bife_1 {
  margin-bottom: 1.5rem;
  display: block;
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
}

._optionGroup_9bife_5 {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5rem;
  max-width: 48rem;
}

@media (min-width: 768px) {
  ._optionGroup_9bife_5 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

._optionGroupFullWidth_9bife_11 {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 24rem;
}

._buttonGroup_9bife_15 {
  text-align: center;
}

._option_9bife_5 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

@media (min-width: 768px) {
  ._option_9bife_5 {
    display: flex;
    justify-content: center;
  }
}

._optionInner_9bife_23 {
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
  border-radius: 0.75rem;
  border-width: 2px;
  border-color: transparent;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 0.75rem 1.5rem;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

@media (min-width: 768px) {
  ._optionInner_9bife_23 {
    height: 10rem;
    width: 10rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  ._optionInner_9bife_23:hover {
    border-color: var(--primary-300, #a99fdf);
    --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1),
      0 2px 4px -2px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
      0 2px 4px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    outline-width: 0px;
  }
}

._option_9bife_5._selected_9bife_44 ._optionInner_9bife_23 {
  border-color: var(--primary-300, #a99fdf);
  background-color: var(--primary-50, #eeecf9);
}

._option_9bife_5._selected_9bife_44 ._optionIcon_9bife_48 {
  background-color: var(--primary-900, #16447a);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

._optionGroupCols1_9bife_53 ._option_9bife_5 {
  width: 100%;
}

@media (min-width: 768px) {
  ._optionGroupCols2_9bife_58 {
    width: 24rem;
  }

  ._optionGroupCols2_9bife_58 ._option_9bife_5 {
    width: 50%;
  }

  ._optionGroupCols3_9bife_64 {
    width: 100%;
    max-width: 36rem;
  }

  ._optionGroupCols3_9bife_64 ._option_9bife_5 {
    width: 33.333333%;
  }

  ._optionGroupCols4_9bife_70 ._option_9bife_5 {
    width: 25%;
  }
}

._buttonGroup_9bife_15 ._option_9bife_5 {
  margin-right: 0;
  border-radius: 0;
}

._buttonGroup_9bife_15 ._option_9bife_5:first-of-type {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

._buttonGroup_9bife_15 ._option_9bife_5:last-of-type {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

._optionGroupFullWidth_9bife_11 ._option_9bife_5 {
  display: flex;
  width: 100%;
  padding: 0.75rem 1.5rem;
}

._optionBody_9bife_90 {
  display: flex;
  align-items: center;
}

@media (min-width: 768px) {
  ._optionBody_9bife_90 {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 8rem;
  }
}

._optionCheckmark_9bife_94 {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-width: 0px;
  background-color: transparent;
}

._optionIcon_9bife_48 {
  margin-right: 1rem;
  display: flex;
  height: 2.5rem;
  width: 2.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  background-color: var(--primary-50, #eeecf9);
  color: var(--primary-700, #533fbe);
}

@media (min-width: 768px) {
  ._optionIcon_9bife_48 {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
  }
}

._optionIcon_9bife_48 svg {
  margin-left: auto;
  margin-right: auto;
  font-size: 1.5rem;
  line-height: 2rem;
}

._optionLabel_9bife_106 {
  display: block;
  text-align: center;
}

._optionDescription_9bife_109 {
  display: block;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--gray-600, #4b5563);
}

._optionGroup2Container_9bife_113 {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3.5rem;
  display: flex;
  max-width: 56rem;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

._optionGroup2Container_9bife_113 > div > input[type="checkbox"] {
  display: none;
}

._optionGroup2Container_9bife_113 > div > label {
  cursor: pointer;
  white-space: nowrap;
  border-radius: 0.5rem;
  border-width: 2px;
  padding: 0.25rem 1rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 600;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

._optionGroup2Container_9bife_113 > div > label:not(._selected_9bife_44) {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  outline-style: solid;
  outline-width: 2px;
  outline-color: var(--gray-200, #e5e7eb);
}

._optionGroup2Container_9bife_113 > div > ._selected_9bife_44 {
  border-color: var(--primary-300, #a99fdf);
  background-color: var(--primary-50, #eeecf9);
  outline: 2px solid transparent;
  outline-offset: 2px;
}

._bold_9bife_133 {
  font-weight: 700;
}

._normal_9bife_137 {
  font-weight: 400;
}

._fieldHeading_dt0yj_1 {
  margin-bottom: 1.5rem;
  display: block;
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
}

._optionGroup_dt0yj_5 {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3.5rem;
  max-width: 24rem;
}

._optionGroupFullWidth_dt0yj_11 {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 24rem;
}

._buttonGroup_dt0yj_15 {
  text-align: center;
}

._option_dt0yj_5 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

._optionInner_dt0yj_23 {
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
  border-radius: 0.75rem;
  border-width: 2px;
  border-color: transparent;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 0.75rem 1.5rem;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  outline: 2px solid transparent;
  outline-width: 1px;
  outline-offset: 0px;
  outline-color: #e4e4e7;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

._option_dt0yj_5._selected_dt0yj_42 ._optionInner_dt0yj_23 {
  border-color: var(--primary-300, #a99fdf);
  background-color: var(--primary-50, #eeecf9);
}

._option_dt0yj_5._selected_dt0yj_42 ._optionIcon_dt0yj_46 {
  background-color: var(--primary-900, #16447a);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

._optionGroupCols1_dt0yj_51 ._option_dt0yj_5 {
  width: 100%;
}

._buttonGroup_dt0yj_15 ._option_dt0yj_5 {
  margin-right: 0;
  border-radius: 0;
}

._buttonGroup_dt0yj_15 ._option_dt0yj_5:first-of-type {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}

._buttonGroup_dt0yj_15 ._option_dt0yj_5:last-of-type {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

._optionGroupFullWidth_dt0yj_11 ._option_dt0yj_5 {
  display: flex;
  width: 100%;
  padding: 0.75rem 1.5rem;
}

._optionBody_dt0yj_73 {
  display: flex;
  align-items: center;
}

._optionCheckmark_dt0yj_77 {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

._optionIcon_dt0yj_46 {
  margin-right: 1rem;
  display: flex;
  height: 2.5rem;
  width: 2.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  background-color: var(--primary-50, #eeecf9);
  color: var(--primary-700, #533fbe);
}

._optionIcon_dt0yj_46 svg {
  margin-left: auto;
  margin-right: auto;
  font-size: 1.5rem;
  line-height: 2rem;
}

._optionLabel_dt0yj_89 {
  display: block;
  text-align: center;
  font-weight: 700;
}

._optionDescription_dt0yj_92 {
  display: block;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--gray-600, #4b5563);
}

._schoolLogoList_yjepu_1 h4 {
  margin-bottom: 1rem;
  margin-top: 3rem;
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: var(--primary-600, #3a7fd0);
}

._schoolLogoList_yjepu_1 span {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  max-width: 72rem;
  align-items: center;
  padding: 1rem;
}

._schoolLogoList_yjepu_1 ._scrollContainer_yjepu_9 {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  -moz-column-gap: 1rem;
  column-gap: 1rem;
  overflow: hidden;
}

@media (min-width: 768px) {
  ._schoolLogoList_yjepu_1 ._scrollContainer_yjepu_9 {
    -moz-column-gap: 2rem;
    column-gap: 2rem;
  }
}

._schoolLogoList_yjepu_1 ._gradientLeft_yjepu_13 {
  position: absolute;
  left: -2.5rem;
  z-index: 10;
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
  width: 100%;
  max-width: 7rem;
  background-color: transparent;
  background-image: linear-gradient(to left, var(--tw-gradient-stops));
  --tw-gradient-from: transparent;
  --tw-gradient-to: rgb(0 0 0 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: var(--gray-50, #f9fafb);
}

@media (min-width: 768px) {
  ._schoolLogoList_yjepu_1 ._gradientLeft_yjepu_13 {
    left: 0px;
  }
}

._schoolLogoList_yjepu_1 ._gradientRight_yjepu_16 {
  position: absolute;
  right: -2.5rem;
  z-index: 10;
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
  width: 100%;
  max-width: 7rem;
  background-color: transparent;
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: transparent;
  --tw-gradient-to: rgb(0 0 0 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: var(--gray-50, #f9fafb);
}

@media (min-width: 768px) {
  ._schoolLogoList_yjepu_1 ._gradientRight_yjepu_16 {
    right: 0px;
  }
}

._miniLogoCard_6vo6a_1 {
  min-width: 5rem;
  border-radius: 0.375rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 1rem;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@media (min-width: 768px) {
  ._miniLogoCard_6vo6a_1 {
    min-width: 6rem;
  }
}

._miniLogoCard_6vo6a_1 img {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

._callout_1qfey_1 {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  display: flex;
  max-width: -moz-fit-content;
  max-width: fit-content;
  flex-direction: column;
  border-radius: 0.375rem;
  background-color: var(--primary-50, #eeecf9);
  padding: 1rem;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1rem;
  font-style: italic;
}

._callout_1qfey_1 b {
  padding-bottom: 0.5rem;
}

._callout_1qfey_1 p {
  font-size: 0.75rem;
  line-height: 1rem;
}

._conditional_gamud_1 > h3 {
  margin-bottom: 1.5rem;
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
}

._overlay_17d1i_1 {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

._dark_17d1i_5 {
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  --tw-bg-opacity: 0.5;
}

._popupContainer_1gqp6_1 {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
}

._popupContainer_1gqp6_1 ._popup_1gqp6_1 {
  position: relative;
  height: 100%;
  width: 100vw;
  max-width: 72rem;
  overflow-y: scroll;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

@media (min-width: 1024px) {
  ._popupContainer_1gqp6_1 ._popup_1gqp6_1 {
    height: auto;
    max-height: 95vh;
    border-radius: 0.5rem;
  }
}

._popupContainer_1gqp6_1 ._popup_1gqp6_1 {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

._popupContainer_1gqp6_1 ._popup_1gqp6_1::-webkit-scrollbar {
  display: none;
}

@media (max-width: 624px) {
  ._popupContainer_1gqp6_1 ._popup_1gqp6_1 {
    min-height: -webkit-fill-available;
    -webkit-overflow-scrolling: touch;
  }
}

._popupContainer_1gqp6_1 ._popup_1gqp6_1 ._closeBtn_1gqp6_23 {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

._disciplineDescriptionPanel_g3072_1 {
  margin-left: auto;
  margin-right: auto;
  max-width: 32rem;
  cursor: pointer;
  padding: 1rem 2rem;
  text-align: center;
}

._heading_g3072_5 {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.5rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
}

._heading_g3072_5 span {
  border-bottom-width: 2px;
  border-style: dashed;
  border-color: var(--gray-400, #9ca3af);
}

._heading_g3072_5 svg {
  margin-right: 0.75rem;
}

._panel_g3072_16 {
  padding: 1.75rem 1.5rem;
}

@media (min-width: 640px) {
  ._panel_g3072_16 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

._popUpHeading_g3072_20 {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3.5rem;
  margin-top: 2rem;
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
}

._itemsContainer_g3072_24 {
  display: flex;
  flex-wrap: wrap;
}

._item_g3072_24 {
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  border-width: 1px;
  border-color: var(--gray-100, #f3f4f6);
  padding: 0.75rem;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@media (min-width: 640px) {
  ._item_g3072_24 {
    padding-right: 2.25rem;
  }
}

@media (min-width: 768px) {
  ._item_g3072_24 {
    margin: 0;
    flex-basis: 33.333333%;
    border-style: none;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-bottom: 3rem;
    padding-top: 0;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
}

._itemHeader_g3072_32 {
  margin-left: 0.5rem;
  display: flex;
}

._iconCircle_g3072_36 {
  border-radius: 9999px;
  background-color: var(--gray-100, #f3f4f6);
  padding: 0.75rem;
}

._itemHeading_g3072_40 {
  margin-left: 0.75rem;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
}

._itemDescription_g3072_44 {
  margin: 1.25rem 0.25rem 0.25rem;
  font-size: 10px;
  line-height: 20px;
  line-height: 1rem;
  color: var(--gray-700, #374151);
}

._wrapper_1mnvd_1 {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

@media (min-width: 640px) {
  ._wrapper_1mnvd_1 {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  ._wrapper_1mnvd_1 {
    max-width: 768px;
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

@media (min-width: 1024px) {
  ._wrapper_1mnvd_1 {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  ._wrapper_1mnvd_1 {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  ._wrapper_1mnvd_1 {
    max-width: 1536px;
  }
}

._wrapper_1mnvd_1 {
  position: relative;
  z-index: 10;
  margin-top: -6rem;
  min-height: 100vh;
  flex-direction: column;
  overflow-y: hidden;
  background-color: var(--gray-50, #f9fafb);
  padding: 0;
  padding-top: 1.75rem;
  text-align: center;
}

@media (min-width: 768px) {
  ._wrapper_1mnvd_1 {
    margin-top: -4rem;
  }
}

._wrapper_1mnvd_1 > p {
  margin-bottom: 0.5rem;
  color: var(--primary-700, #533fbe);
}

._heading_1mnvd_8 {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
  margin-top: 0;
  margin-bottom: 0;
  max-width: 36rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
}

@media (min-width: 768px) {
  ._heading_1mnvd_8 {
    margin-left: auto;
    margin-right: auto;
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

._dotContainer_1mnvd_12 {
  margin-top: 5rem;
  margin-bottom: 13rem;
  display: flex;
  align-items: center;
  justify-content: center;
  -moz-column-gap: 0.25rem;
  column-gap: 0.25rem;
}

._dot_1mnvd_12 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  height: 2rem;
  width: 2rem;
}

@keyframes _grow_1mnvd_1 {
  0%,
  to {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }
}

._dot_1mnvd_12 {
  animation: _grow_1mnvd_1 2s ease-in-out infinite;
  border-radius: 9999px;
  background-color: var(--secondary-700, #d0021b);
}

._cardContainer_1mnvd_20 {
  margin-left: auto;
  margin-right: auto;
  display: grid;
  max-width: 56rem;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 0.5rem;
}

@media (min-width: 768px) {
  ._cardContainer_1mnvd_20 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

._card_1mnvd_20 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

@media (min-width: 768px) {
  ._card_1mnvd_20 {
    flex-direction: column;
    justify-content: flex-start;
  }
}

._card_1mnvd_20 svg {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

@media (min-width: 768px) {
  ._card_1mnvd_20 svg {
    height: 10rem;
    width: 100%;
  }
}

._card_1mnvd_20 p {
  margin-bottom: 0;
  width: 50%;
  text-align: left;
  vertical-align: middle;
  font-size: 0.75rem;
  line-height: 1rem;
}

@media (min-width: 768px) {
  ._card_1mnvd_20 p {
    width: 100%;
    text-align: center;
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  ._card_1mnvd_20 strong {
    display: block;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

._wrapperPreamp_1mnvd_41 {
  margin-top: 5rem;
}

._schoolsList_1mnvd_45 {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 1rem;
}

._phoneField_10uag_1 {
  width: 100%;
  border-radius: 9999px;
  border-width: 1px;
  border-style: solid;
  --tw-border-opacity: 1;
  border-color: rgb(228 228 231 / var(--tw-border-opacity));
  padding: 1rem 1.5rem;
}

._error_10uag_5 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

._inputContainer_eya3i_1 {
  margin-top: 2rem;
  display: flex;
  border-radius: 9999px;
  border-width: 1px;
  border-style: solid;
  --tw-border-opacity: 1;
  border-color: rgb(228 228 231 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

._inputContainer_eya3i_1 > svg {
  margin: 1rem;
  margin-top: auto;
  margin-bottom: auto;
  color: var(--primary-700, #533fbe);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

._textField_eya3i_17 {
  width: 100%;
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1.5rem;
  outline-width: 0px;
}

._textField_eya3i_17:focus {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

@media (min-width: 768px) {
  ._textField_eya3i_17:hover {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

._error_eya3i_21 {
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
}

._error_z9m6o_1 {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

._error_z9m6o_1 svg {
  margin-right: 0.25rem;
}

._headingContainer_1fpvz_1 {
  margin-bottom: 1rem;
  display: block;
  text-align: center;
}

._headingContainer_1fpvz_1 h2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
}

._headingContainer_1fpvz_1 ._soloHeading_1fpvz_8 {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

._headingContainer_1fpvz_1 p {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 400;
  color: var(--primary-700, #533fbe);
}

._progressBar_63yfq_1 {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.5rem;
  margin-bottom: 1.75rem;
  height: 0.5rem;
  max-width: 20rem;
  border-radius: 0.5rem;
  background-color: var(--primary-100, #ddd9f2);
}

@media (min-width: 768px) {
  ._progressBar_63yfq_1 {
    margin-bottom: 6rem;
  }
}

._progress_63yfq_1 {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 0.5rem;
  border-radius: 0.5rem;
  background-color: var(--primary-700, #533fbe);
}

._disclaimer_icov9_1 {
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  max-width: 36rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1rem;
}

@media (min-width: 768px) {
  ._disclaimer_icov9_1 {
    padding-left: 0;
    padding-right: 0;
  }
}

._disclaimer_icov9_1 svg {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.75rem;
}

._content_alp2g_1 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  max-width: 36rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

._content_alp2g_1 h1 {
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: 700;
  color: var(--primary-700, #533fbe);
}

._content_alp2g_1 p {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: var(--gray-500, #6b7280);
}

._content_alp2g_1 button {
  width: 80%;
  border-radius: 9999px;
  background-color: var(--secondary-600, #d51b32);
  padding: 1rem 2rem;
  font-weight: 700;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

._content_alp2g_1 button:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

._overflowXClipped_pmptr_1 {
  overflow-x: clip;
}

._pageContainer_pmptr_5 {
  height: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.5rem;
}

@media (min-width: 768px) {
  ._pageContainer_pmptr_5 {
    padding-left: 0;
    padding-right: 0;
  }
}

._pageContainer_pmptr_5 {
  min-height: calc(100vh - 20rem);
}

._notAnimated_pmptr_10 {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

@media (min-width: 640px) {
  ._notAnimated_pmptr_10 {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  ._notAnimated_pmptr_10 {
    max-width: 768px;
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

@media (min-width: 1024px) {
  ._notAnimated_pmptr_10 {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  ._notAnimated_pmptr_10 {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  ._notAnimated_pmptr_10 {
    max-width: 1536px;
  }
}

@keyframes _page-slide-left_pmptr_1 {
  0% {
    transform: translate(100vw);
  }

  to {
    transform: translate(0);
  }
}

._animateLeft_pmptr_14 {
  animation: _page-slide-left_pmptr_1 0.2s;
}

@keyframes _page-slide-right_pmptr_1 {
  0% {
    transform: translate(-100vw);
  }

  to {
    transform: translate(0);
  }
}

._animateRight_pmptr_18 {
  animation: _page-slide-right_pmptr_1 0.2s;
}

._pageDescription_pmptr_22 {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3.5rem;
  display: block;
  max-width: 48rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(113 113 122 / var(--tw-text-opacity));
}

@media (min-width: 768px) {
  ._pageDescription_pmptr_22 {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

._pageActions_pmptr_26 {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}

._button_pmptr_30 {
  display: inline-flex;
  width: 100%;
  max-width: 24rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.75rem;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  padding: 1rem 0.5rem;
  font-weight: 700;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
}

._button_pmptr_30:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

@media (min-width: 768px) {
  ._button_pmptr_30 {
    width: 13rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

._buttonPrevious_pmptr_34 {
  z-index: 50;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.75rem;
  display: block;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--primary-700, #533fbe);
}

@media (min-width: 768px) {
  ._buttonPrevious_pmptr_34 {
    position: fixed;
    top: 50%;
    left: 6rem;
    margin-bottom: 0;
    --tw-translate-y: -50%;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

._buttonPrevious_pmptr_34 ._buttonChevron_pmptr_37 {
  font-size: 1rem;
  line-height: 1.5rem;
}

@media (min-width: 768px) {
  ._buttonPrevious_pmptr_34 ._buttonChevron_pmptr_37 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

._buttonPrevious_pmptr_34 ._buttonText_pmptr_41 {
  padding-left: 0.5rem;
}

@media (min-width: 768px) {
  ._buttonPrevious_pmptr_34 ._buttonText_pmptr_41 {
    display: none;
  }
}

button._buttonContinue_pmptr_46 {
  display: flex;
  justify-content: center;
  gap: 0.875rem;
  background-color: var(--primary-700, #533fbe);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media (min-width: 768px) {
  button._buttonContinue_pmptr_46:hover {
    background-color: var(--primary-800, #1d5aa2);
  }
}

._button_pmptr_30 + ._button_pmptr_30 {
  margin-left: 1.5rem;
}

._button_pmptr_30 + ._button_pmptr_30 svg {
  margin-right: 0;
  margin-left: 0.5rem;
}

._disclaimer_pmptr_58 {
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  max-width: 48rem;
  color: var(--gray-600, #4b5563);
}

._disclaimer_pmptr_58 p {
  margin-bottom: 0.5rem !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

._disclaimer_pmptr_58 a {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  color: var(--primary-700, #533fbe) !important;
}

._disclaimer_pmptr_58 ul {
  list-style-type: disc !important;
  padding-left: 1rem !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

._flagsContainer_1df2y_1 {
  margin-bottom: 1rem;
  margin-top: 0.5rem;
  min-height: 2rem;
}

._flagsContainer_1df2y_1 ._flagContent_1df2y_4 {
  display: flex;
}

._flagsContainer_1df2y_1
  ._flagContent_1df2y_4
  > :not([hidden])
  ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

._flagsContainer_1df2y_1 ._flag_1df2y_1 {
  display: flex;
  align-items: center;
  border-radius: 0.125rem;
  --tw-bg-opacity: 1;
  background-color: rgb(250 245 255 / var(--tw-bg-opacity));
  padding: 0.25rem;
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--primary-700, #533fbe);
}

._flagsContainer_1df2y_1 ._flag_1df2y_1 > svg {
  margin-right: 0.25rem;
}

._card_1m7qr_2 {
  position: relative;
  display: flex;
  height: 100%;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-bottom: 1.25rem;
}

._card_1m7qr_2 > div {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 0.5rem;
  width: 100%;
  background-color: var(--gray-200, #e5e7eb);
}

._cardLogo_1m7qr_12 {
  position: relative;
  top: 0px;
  z-index: 10;
  display: flex;
  aspect-ratio: auto;
  width: 8rem;
  align-items: center;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  --tw-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0px 0px 15px -3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

._cardLogo_1m7qr_12 > img {
  -o-object-fit: contain;
  object-fit: contain;
}

._cardHeader_1m7qr_21 {
  display: flex;
  justify-content: space-between;
}

._cardHeader_1m7qr_21 > p {
  margin-top: 1.5rem;
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: var(--gray-400, #9ca3af);
}

._cardContent_1m7qr_31 {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
}

._cardContent_1m7qr_31 > p {
  margin-bottom: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.25rem;
  color: var(--primary-600, #3a7fd0);
}

._cardContent_1m7qr_31 > h3 {
  margin-bottom: 0.5rem;
  margin-top: 0;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.5rem;
  color: var(--gray-900, #111827);
}

._cardDescription_1m7qr_46 {
  position: relative;
  max-height: 0px;
  overflow: hidden;
}

@media (min-width: 768px) {
  ._cardDescription_1m7qr_46 {
    max-height: 3.5rem;
  }
}

._cardDescription_1m7qr_46 > p {
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: var(--gray-600, #4b5563);
}

._cardDescription_1m7qr_46 > div {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 3rem;
  width: 100%;
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
  --tw-gradient-from: #fff;
  --tw-gradient-to: rgb(255 255 255 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

._cardDescription_1m7qr_46._expand_1m7qr_60 {
  max-height: 100%;
}

._cardDescriptionToggle_1m7qr_66 {
  margin-bottom: 0.5rem;
  text-align: left;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.25rem;
  color: var(--primary-700, #533fbe);
}

button._cardCta_1m7qr_71 {
  display: block;
  width: 100%;
  border-radius: 0.375rem;
  background-color: var(--secondary-700, #d0021b);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

button._cardCta_1m7qr_71:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

@media (min-width: 768px) {
  button._cardCta_1m7qr_71:hover {
    background-color: var(--secondary-800, #a60216);
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}

button._cardCta_1m7qr_71:disabled {
  cursor: not-allowed;
  background-color: var(--gray-400, #9ca3af);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

button._cardCta_1m7qr_71 ._ctaText_1m7qr_79 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
}

button._cardCta_1m7qr_71 ._ctaText_1m7qr_79 ._iconContainer_1m7qr_82 {
  display: flex;
  align-items: center;
  -moz-column-gap: 0.25rem;
  column-gap: 0.25rem;
}

button._cardCta_1m7qr_71 ._ctaText_1m7qr_79 ._ctaLink_1m7qr_86 {
  display: flex;
  align-items: center;
}

button._cardCta_1m7qr_71
  ._ctaText_1m7qr_79
  ._ctaLink_1m7qr_86
  > :not([hidden])
  ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

button._cardCta_1m7qr_71 ._ctaText_1m7qr_79 ._ctaLink_1m7qr_86 {
  color: var(--primary-700, #533fbe);
  text-decoration-line: underline;
}

._hide_1m7qr_93 {
  display: none;
}

._spread_1m7qr_97 {
  justify-content: space-between;
}

._spread_1m7qr_97 > h3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

._stretch_1m7qr_105 {
  flex: 1 1 0%;
}

._card_1iyay_1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -moz-column-gap: 0.5rem;
  column-gap: 0.5rem;
  border-radius: 0.5rem;
  border-width: 1px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 0.75rem 0.75rem 0.75rem 1.25rem;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

._card_1iyay_1 ._logo_1iyay_4 {
  margin-right: 1rem;
  display: flex;
  aspect-ratio: auto;
  height: 4rem;
  align-items: center;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

._card_1iyay_1 ._logo_1iyay_4 > img {
  min-width: 70px;
  -o-object-fit: contain;
  object-fit: contain;
}

._card_1iyay_1 ._subtitle_1iyay_12 {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
  color: var(--primary-700, #533fbe);
}

._card_1iyay_1 ._removeBtn_1iyay_16 {
  margin-left: auto;
  align-self: flex-start;
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: var(--gray-300, #d1d5db);
}

._results_n1b80_1 {
  display: grid;
  flex: 1 1 0%;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 2rem;
  padding-left: 0;
  padding-right: 0;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

@media (min-width: 768px) {
  ._results_n1b80_1 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

@media (min-width: 1024px) {
  ._results_n1b80_1 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

._results_n1b80_1 > li {
  overflow: hidden;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0px 10px 25px -5px rgba(0, 0, 0, 0.1),
    0px 5px 10px -5px rgba(0, 0, 0, 0.04);
  --tw-shadow-colored: 0px 10px 25px -5px var(--tw-shadow-color),
    0px 5px 10px -5px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

._resultsWithSideBar_n1b80_9 {
  display: grid;
  flex: 1 1 0%;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 2rem;
  padding-left: 0;
  padding-right: 0;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

@media (min-width: 768px) {
  ._resultsWithSideBar_n1b80_9 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

@media (min-width: 1024px) {
  ._resultsWithSideBar_n1b80_9 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

._resultsWithSideBar_n1b80_9 > li {
  overflow: hidden;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0px 10px 25px -5px rgba(0, 0, 0, 0.1),
    0px 5px 10px -5px rgba(0, 0, 0, 0.04);
  --tw-shadow-colored: 0px 10px 25px -5px var(--tw-shadow-color),
    0px 5px 10px -5px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

._showMore_n1b80_17 {
  width: 100%;
  border-radius: 0.375rem;
  background-color: var(--primary-100, #ddd9f2);
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 2rem;
  color: var(--primary-700, #533fbe);
}

._searchBoxContainer_te55k_1 {
  margin-bottom: 1rem;
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 0.375rem;
  border-width: 1px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 1rem;
}

._searchBoxInput_te55k_5 {
  width: 100%;
  background-color: transparent;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

._searchBoxInput_te55k_5:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

._searchBoxSubmit_te55k_9 {
  margin-right: 1rem;
  margin-left: 0.5rem;
  height: 1.5rem;
  width: 1.5rem;
}

._facetButton_1tv2m_1 {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  border-width: 1px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
  color: var(--gray-600, #4b5563);
}

._facetButton_1tv2m_1._active_1tv2m_4 {
  border-color: var(--primary-300, #a99fdf);
  background-color: var(--primary-50, #eeecf9);
}

._icon_1tv2m_9 {
  margin-right: 0.25rem;
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 9999px;
  background-color: var(--gray-200, #e5e7eb);
  color: var(--gray-200, #e5e7eb);
}

._icon_1tv2m_9._active_1tv2m_4 {
  background-color: var(--primary-400, #669cdb);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

._mobileDrawer_k3ojb_1 {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 50;
  display: none;
  flex-direction: column;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

._mobileDrawer_k3ojb_1._open_k3ojb_4 {
  display: flex;
  overflow: scroll;
}

._mobileDrawerHeader_k3ojb_9 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom-width: 2px;
  padding: 1.25rem 1.5rem;
}

._mobileDrawerHeader_k3ojb_9 > p {
  display: flex;
  align-items: center;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 2rem;
  color: var(--gray-700, #374151);
}

._mobileDrawerHeader_k3ojb_9 > p ._icon_k3ojb_15 {
  margin-right: 0.25rem;
  width: 1.5rem;
  color: var(--gray-400, #9ca3af);
}

._mobileDrawerHeader_k3ojb_9 button ._icon_k3ojb_15 {
  height: 1.25rem;
  width: 1.25rem;
  color: var(--gray-500, #6b7280);
}

._mobileDrawerContent_k3ojb_25 {
  margin-bottom: auto;
}

._mobileDrawerFooter_k3ojb_29 {
  position: sticky;
  bottom: 0px;
  left: 0px;
  z-index: 10;
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 0.75rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  --tw-shadow: 0px -5px 15px -3px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0px -5px 15px -3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

._mobileDrawerFooter_k3ojb_29 ._viewSchoolsButton_k3ojb_32 {
  border-radius: 9999px;
  background-color: var(--primary-500, #508dd5);
  padding: 0.5rem 1.75rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.75rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

._mobileDrawerResultsAndSort_k3ojb_37 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2rem;
}

@media (min-width: 768px) {
  ._mobileDrawerResultsAndSort_k3ojb_37 {
    display: none;
  }
}

._facetDropdown_1ac0n_1 {
  position: relative;
}

._facetDropdown_1ac0n_1 ._open_1ac0n_4 {
  display: block;
}

._facetDropdown_1ac0n_1 > div {
  position: absolute;
  left: 0px;
  z-index: 50;
  margin-top: 0.5rem;
  display: none;
  width: 20rem;
  border-radius: 0.75rem;
  border-width: 2px;
  border-color: var(--primary-400, #669cdb);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 1.25rem 1.75rem;
  --tw-shadow: 0px 10px 25px -5px rgba(0, 0, 0, 0.1),
    0px 5px 10px -5px rgba(0, 0, 0, 0.04);
  --tw-shadow-colored: 0px 10px 25px -5px var(--tw-shadow-color),
    0px 5px 10px -5px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

._filtersButton_1pfyd_1 {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  background-color: var(--primary-700, #533fbe);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 2rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@media (min-width: 768px) {
  ._filtersButton_1pfyd_1 {
    display: none;
  }
}

._filtersButton_1pfyd_1 span {
  margin-left: 0.25rem;
}

._icon_1pfyd_9 {
  margin-right: 0.25rem;
  height: 1.5rem;
  width: 1.5rem;
  color: var(--primary-200, #92b8e5);
}

._button_ca0wz_1 {
  border-radius: 9999px;
  border-width: 2px;
  padding: 0.5rem 1.25rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.75rem;
  color: var(--gray-500, #6b7280);
}

._text_ca0wz_5 {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: var(--primary-600, #3a7fd0);
}

._sponsoredResults_9w7uw_1 {
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 0.5rem;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.5rem;
  color: var(--primary-600, #3a7fd0);
}

._infoIcon_9w7uw_5 {
  cursor: pointer;
  font-weight: 700;
  color: var(--primary-600, #3a7fd0);
}

._sponsoredResultsWrapper_9w7uw_9 {
  display: flex;
  align-items: center;
}

._tooltipContent_19o85_1 {
  display: flex;
  width: 100%;
  flex-direction: column;
}

._tooltipWrapper_19o85_5 {
  position: relative;
}

._tooltip_19o85_1 {
  position: absolute;
  top: 1.5rem;
  z-index: 50;
  width: 20rem;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 1.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color),
    0 8px 10px -6px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@media (min-width: 768px) {
  ._tooltip_19o85_1 {
    right: -10rem;
    width: 24rem;
  }
}

._tooltipTopBorder_19o85_13 {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 0.5rem;
  width: 100%;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background-color: var(--gray-200, #e5e7eb);
}

._tooltipContent_19o85_1 {
  display: flex;
  flex-direction: column;
}

button._tooltipButton_19o85_21 {
  margin-top: 1rem;
  display: block;
  width: 100%;
  border-radius: 0.375rem;
  background-color: var(--secondary-700, #d0021b);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media (min-width: 768px) {
  button._tooltipButton_19o85_21:hover {
    background-color: var(--secondary-800, #a60216);
  }
}

._wrapper_ukohj_1 {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

@media (min-width: 640px) {
  ._wrapper_ukohj_1 {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  ._wrapper_ukohj_1 {
    max-width: 768px;
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

@media (min-width: 1024px) {
  ._wrapper_ukohj_1 {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  ._wrapper_ukohj_1 {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  ._wrapper_ukohj_1 {
    max-width: 1536px;
  }
}

._wrapper_ukohj_1 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  text-align: center;
}

._wrapper_ukohj_1 > p {
  margin-bottom: 0.5rem;
  color: var(--primary-700, #533fbe);
}

._heading_ukohj_8 {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
  margin-top: 0;
  margin-bottom: 0;
  max-width: 36rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
}

@media (min-width: 768px) {
  ._heading_ukohj_8 {
    margin-left: auto;
    margin-right: auto;
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

._dotContainer_ukohj_12 {
  margin-top: 5rem;
  margin-bottom: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  -moz-column-gap: 0.25rem;
  column-gap: 0.25rem;
}

._dot_ukohj_12 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  height: 2rem;
  width: 2rem;
}

@keyframes _grow_ukohj_1 {
  0%,
  to {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }
}

._dot_ukohj_12 {
  animation: _grow_ukohj_1 2s ease-in-out infinite;
  border-radius: 9999px;
  background-color: var(--secondary-700, #d0021b);
}

._cardContainer_ukohj_20 {
  margin-left: auto;
  margin-right: auto;
  display: grid;
  max-width: 56rem;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 0.5rem;
}

@media (min-width: 768px) {
  ._cardContainer_ukohj_20 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

._card_ukohj_20 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;
}

@media (min-width: 768px) {
  ._card_ukohj_20 {
    flex-direction: column;
    justify-content: flex-start;
  }
}

._card_ukohj_20 svg {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

@media (min-width: 768px) {
  ._card_ukohj_20 svg {
    height: 10rem;
    width: 100%;
  }
}

._card_ukohj_20 p {
  margin-bottom: 0;
  width: 50%;
  text-align: left;
  vertical-align: middle;
  font-size: 0.75rem;
  line-height: 1rem;
}

@media (min-width: 768px) {
  ._card_ukohj_20 p {
    width: 100%;
    text-align: center;
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  ._card_ukohj_20 strong {
    display: block;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

._wrapperPreamp_ukohj_41 {
  margin-top: 5rem;
}

._schoolsList_ukohj_45 {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 1rem;
}

._exitStrategyContainer_ms6fp_1 {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
}

._exitStrategyContainer_ms6fp_1 ._header_ms6fp_4 ._title_ms6fp_5 {
  margin-bottom: 1rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
}

._exitStrategyContainer_ms6fp_1 h2 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
}

._exitStrategyContainer_ms6fp_1 h3 {
  margin-bottom: 1rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
}

._exitStrategyContainer_ms6fp_1 ._admissionsText_ms6fp_18 {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

@media (min-width: 1024px) {
  ._exitStrategyContainer_ms6fp_1 ._admissionsText_ms6fp_18 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

._exitStrategyContainer_ms6fp_1
  ._admissionsText_ms6fp_18._admissionsCollapsible_ms6fp_21 {
  margin-bottom: 0;
  max-height: 4rem;
  overflow: hidden;
}

._exitStrategyContainer_ms6fp_1 ._admissionsText_ms6fp_18._expand_ms6fp_25 {
  max-height: 100%;
}

._exitStrategyContainer_ms6fp_1 ._admissionsText_ms6fp_18 ._gradient_ms6fp_29 {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 3rem;
  width: 100%;
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
  --tw-gradient-from: #fff;
  --tw-gradient-to: rgb(255 255 255 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

._exitStrategyContainer_ms6fp_1 ._admissionsText_ms6fp_18 a {
  font-weight: 700;
  color: var(--primary-600, #3a7fd0);
  text-decoration-line: underline;
}

._exitStrategyContainer_ms6fp_1 ._applicationContainer_ms6fp_37 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

@media (min-width: 1024px) {
  ._exitStrategyContainer_ms6fp_1 ._applicationContainer_ms6fp_37 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

._exitStrategyContainer_ms6fp_1 ._applicationContainer_ms6fp_37 h4 {
  margin-bottom: 1rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
}

._exitStrategyContainer_ms6fp_1 ._applicationContainer_ms6fp_37 button {
  margin-bottom: 1.5rem;
  display: flex;
  height: 3rem;
  width: 100%;
  max-width: 36rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  background-color: var(--secondary-700, #d0021b);
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media (min-width: 1024px) {
  ._exitStrategyContainer_ms6fp_1 ._applicationContainer_ms6fp_37 button {
    margin-bottom: 2rem;
  }
}

._exitStrategyContainer_ms6fp_1
  ._applicationContainer_ms6fp_37
  ._applicationCtaIcon_ms6fp_48 {
  margin-left: 0.5rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

._exitStrategyContainer_ms6fp_1 ._expandButton_ms6fp_53 {
  margin-bottom: 1rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
  color: var(--primary-700, #533fbe);
}

._exitStrategyContainer_ms6fp_1 ._cardsContainer_ms6fp_57 h4 {
  margin-bottom: 1rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
}

._exitStrategyContainer_ms6fp_1 ._cardsContainer_ms6fp_57 ._card_ms6fp_57 {
  border-top-width: 2px;
  border-color: var(--gray-200, #e5e7eb);
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

@media (min-width: 1024px) {
  ._exitStrategyContainer_ms6fp_1 ._cardsContainer_ms6fp_57 ._card_ms6fp_57 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

._exitStrategyContainer_ms6fp_1
  ._cardsContainer_ms6fp_57
  ._card_ms6fp_57
  ._iconContainer_ms6fp_65 {
  margin-bottom: 0.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

@media (min-width: 768px) {
  ._exitStrategyContainer_ms6fp_1
    ._cardsContainer_ms6fp_57
    ._card_ms6fp_57
    ._iconContainer_ms6fp_65 {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

._exitStrategyContainer_ms6fp_1
  ._cardsContainer_ms6fp_57
  ._card_ms6fp_57
  ._cardContent_ms6fp_69
  ._defaultText_ms6fp_70
  ul {
  margin-bottom: 0.5rem;
}

._exitStrategyContainer_ms6fp_1
  ._cardsContainer_ms6fp_57
  ._card_ms6fp_57
  ._cardContent_ms6fp_69
  ._defaultText_ms6fp_70
  p:last-child {
  font-weight: 600;
}

._exitStrategyContainer_ms6fp_1 ._cardsContainer_ms6fp_57 ._card_ms6fp_57 p {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

._exitStrategyContainer_ms6fp_1
  ._cardsContainer_ms6fp_57
  ._card_ms6fp_57
  p:last-child {
  margin-bottom: 0;
  padding-top: 0.75rem;
  font-weight: 600;
}

@media (min-width: 1024px) {
  ._exitStrategyContainer_ms6fp_1 ._cardsContainer_ms6fp_57 ._card_ms6fp_57 p {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

._exitStrategyContainer_ms6fp_1 ._schoolCard_ms6fp_87 {
  margin-bottom: 1.5rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.375rem;
  background-color: var(--gray-50, #f9fafb);
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

._exitStrategyContainer_ms6fp_1 ._schoolCard_ms6fp_87 ._logo_ms6fp_89 {
  margin-right: 1rem;
  display: flex;
  aspect-ratio: auto;
  height: 4rem;
  align-items: center;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

._exitStrategyContainer_ms6fp_1 ._schoolCard_ms6fp_87 ._logo_ms6fp_89 > img {
  min-width: 70px;
  -o-object-fit: contain;
  object-fit: contain;
}

._exitStrategyContainer_ms6fp_1
  ._schoolCard_ms6fp_87
  ._datesContainer_ms6fp_96 {
  display: flex;
  height: 2.75rem;
  flex-direction: column;
  justify-content: center;
}

._exitStrategyContainer_ms6fp_1 ._schoolCard_ms6fp_87 ._spaceDates_ms6fp_100 {
  justify-content: space-between;
}

._exitStrategyContainer_ms6fp_1 ._schoolCard_ms6fp_87 ._dates_ms6fp_96 {
  display: flex;
  align-items: center;
}

._exitStrategyContainer_ms6fp_1 ._schoolCard_ms6fp_87 ._dates_ms6fp_96 span {
  font-weight: 700;
}

._exitStrategyContainer_ms6fp_1 ._schoolCard_ms6fp_87 ._noDate_ms6fp_112 {
  text-align: right;
}

._exitStrategyContainer_ms6fp_1 ._icon_ms6fp_65 {
  margin-right: 0.5rem;
  color: var(--primary-600, #3a7fd0);
}

._exitStrategyContainer_ms6fp_1 ._conclusion_ms6fp_120 {
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top-width: 2px;
  border-color: var(--gray-200, #e5e7eb);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 1rem;
}

._exitStrategyContainer_ms6fp_1 ._conclusion_ms6fp_120 h4 {
  margin-bottom: 0.25rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
}

._exitStrategyContainer_ms6fp_1 ._conclusion_ms6fp_120 p {
  margin-bottom: 1rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: -0.025em;
  color: var(--gray-700, #374151);
}

._exitStrategyContainer_ms6fp_1 ._conclusion_ms6fp_120 button {
  height: 3rem;
  width: 100%;
  max-width: 36rem;
  border-radius: 9999px;
  background-color: var(--primary-700, #533fbe);
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

._exitStrategyContainer_ms6fp_1 ._scrollContainer_ms6fp_135 {
  flex: 1 1 0%;
  overflow-y: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@media (min-width: 1024px) {
  ._exitStrategyContainer_ms6fp_1 ._scrollContainer_ms6fp_135 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

._loadingContainer_ms6fp_140 {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

._loadingContainer_ms6fp_140 ._loadingText_ms6fp_143 {
  margin-bottom: -3.5rem;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
}

._preampNextSteps_ms6fp_148 {
  margin-bottom: 0.875rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
}

._collapsibleTrigger_1gayy_1 {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: var(--gray-100, #f3f4f6);
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 2.25rem;
  color: var(--gray-500, #6b7280);
}

._collapsibleTrigger_1gayy_1:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

._active_1gayy_5 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  color: var(--primary-600, #3a7fd0);
}

._icon_1gayy_9 {
  height: 1.25rem;
  width: 1.25rem;
}

._collapsibleSection_k7hq1_1 {
  border-bottom-width: 2px;
}

._collapsibleSection_k7hq1_1 > div {
  display: none;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  padding-bottom: 0.5rem;
}

._collapsibleSection_k7hq1_1 > div._open_k7hq1_7 {
  display: block;
}

._resourcesBanner_2tj0n_1 {
  position: fixed;
  bottom: 0px;
  z-index: 10;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

._resourcesBanner_2tj0n_1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

._resourcesBanner_2tj0n_1 {
  background-color: var(--primary-100, #ddd9f2);
  padding: 1.5rem;
}

@media (min-width: 768px) {
  ._resourcesBanner_2tj0n_1 {
    flex-direction: row;
  }

  ._resourcesBanner_2tj0n_1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
}

._resourcesBanner_2tj0n_1 ._content_2tj0n_4 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

._resourcesBanner_2tj0n_1 ._content_2tj0n_4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}

._resourcesBanner_2tj0n_1 ._content_2tj0n_4 {
  text-align: center;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--gray-700, #374151);
}

@media (min-width: 768px) {
  ._resourcesBanner_2tj0n_1 ._content_2tj0n_4 {
    flex-direction: row;
  }

  ._resourcesBanner_2tj0n_1
    ._content_2tj0n_4
    > :not([hidden])
    ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
}

._resourcesBanner_2tj0n_1 ._content_2tj0n_4 span {
  font-weight: 700;
  color: var(--primary-700, #533fbe);
}

@media (min-width: 768px) {
  ._resourcesBanner_2tj0n_1 ._content_2tj0n_4 span {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
}

._resourcesBanner_2tj0n_1 a {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

._resourcesBanner_2tj0n_1 a > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

._resourcesBanner_2tj0n_1 a {
  border-radius: 9999px;
  background-color: var(--primary-600, #3a7fd0);
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media (min-width: 768px) {
  ._resourcesBanner_2tj0n_1 a {
    width: auto;
  }
}

._programSelect_855yj_1 {
  height: 100%;
  background-color: var(--primary-50, #eeecf9);
}

._programSelect_855yj_1 ._content_855yj_4 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 2rem;
}

@media (min-width: 1024px) {
  ._programSelect_855yj_1 ._content_855yj_4 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

._programSelect_855yj_1 ._content_855yj_4 header {
  width: 100%;
}

._programSelect_855yj_1 ._content_855yj_4 header ._personalization_855yj_10 {
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 600;
}

@media (min-width: 768px) {
  ._programSelect_855yj_1 ._content_855yj_4 header ._personalization_855yj_10 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

._programSelect_855yj_1 ._content_855yj_4 header ._title_855yj_14 {
  text-align: center;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
}

@media (min-width: 768px) {
  ._programSelect_855yj_1 ._content_855yj_4 header ._title_855yj_14 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

._programSelect_855yj_1 ._content_855yj_4 header ._subtitle_855yj_18 {
  margin-top: 0.5rem;
  text-align: center;
  font-size: 1rem;
  line-height: 1.5rem;
}

._programSelect_855yj_1 ._content_855yj_4 ._cards_855yj_23 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 1.5rem;
  -moz-column-gap: 0px;
  column-gap: 0px;
}

@media (min-width: 1024px) {
  ._programSelect_855yj_1 ._content_855yj_4 ._cards_855yj_23 {
    flex-direction: row;
    row-gap: 0px;
    -moz-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }
}

._programSelect_855yj_1 ._actions_855yj_28 {
  position: sticky;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 10;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  border-top-width: 2px;
  border-color: var(--primary-100, #ddd9f2);
  background-color: var(--primary-50, #eeecf9);
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0;
}

@media (min-width: 768px) {
  ._programSelect_855yj_1 ._actions_855yj_28 {
    margin-top: 0;
  }
}

@media (min-width: 1024px) {
  ._programSelect_855yj_1 ._actions_855yj_28 {
    position: static;
    border-top-width: 0px;
    padding-bottom: 2rem;
  }
}

._programSelect_855yj_1 ._actions_855yj_28 ._submitBtn_855yj_31 {
  margin-top: 1.25rem;
  display: inline-flex;
  width: 100%;
  justify-content: center;
  border-radius: 9999px;
  background-color: var(--secondary-500, #d93549);
  padding: 1rem 4rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media (min-width: 1024px) {
  ._programSelect_855yj_1 ._actions_855yj_28 ._submitBtn_855yj_31 {
    margin-left: auto;
    margin-right: auto;
    width: auto;
  }
}

._programSelect_855yj_1 ._actions_855yj_28 ._submitBtn_855yj_31:disabled {
  background-color: var(--gray-200, #e5e7eb);
}

._programSelect_855yj_1 ._actions_855yj_28 ._skipToResults_855yj_39 {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.25rem;
  margin-bottom: 1.5rem;
  display: flex;
  font-weight: 700;
  color: var(--primary-700, #533fbe);
}

._programSelect_855yj_1 ._exitButton_855yj_44 {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}

._programSelect_855yj_1 ._selectSchoolText_855yj_48 {
  margin-top: 1rem;
  white-space: nowrap;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--gray-500, #6b7280);
}

@media (min-width: 768px) {
  ._programSelect_855yj_1 ._selectSchoolText_855yj_48 {
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 700;
    --tw-text-opacity: 1;
    color: rgb(0 0 0 / var(--tw-text-opacity));
  }
}

._programSelect_855yj_1 ._bestFitSection_855yj_52 {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}

@media (min-width: 1024px) {
  ._programSelect_855yj_1 ._bestFitSection_855yj_52 {
    flex-direction: row;
  }
}

._programSelect_855yj_1 ._bestFitSection_855yj_52 span {
  margin-top: 0.25rem;
  margin-left: 0.75rem;
  display: flex;
  align-items: center;
  font-weight: 400;
}

@media (min-width: 1024px) {
  ._programSelect_855yj_1 ._bestFitSection_855yj_52 span {
    margin-top: 0;
  }
}

._loadingContainer_855yj_61 {
  display: flex;
  height: 100%;
  min-height: 45vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

._loadingContainer_855yj_61 h2 {
  text-align: center;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
}

._card_23ltk_1 {
  position: relative;
  height: auto;
  width: 100%;
  cursor: pointer;
  border-radius: 0.5rem;
  border-width: 1px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-bottom: 1rem;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@media (min-width: 1024px) {
  ._card_23ltk_1 {
    max-width: 24rem;
  }
}

._card_23ltk_1 ._cardTopBorder_23ltk_4 {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  height: 0.625rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background-color: var(--primary-200, #92b8e5);
}

._card_23ltk_1 ._cardHeader_23ltk_8 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
}

._card_23ltk_1 ._cardHeader_23ltk_8 ._logo_23ltk_11 {
  height: 5rem;
  width: 7rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 0.5rem 0.5rem 1rem;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

._card_23ltk_1 ._cardHeader_23ltk_8 ._location_23ltk_15 {
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--gray-500, #6b7280);
}

._card_23ltk_1 ._cardBody_23ltk_20 {
  margin-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

._card_23ltk_1 ._cardBody_23ltk_20 ._subtitleContainer_23ltk_23 {
  line-height: 1rem;
}

@media (min-width: 640px) {
  ._card_23ltk_1 ._cardBody_23ltk_20 ._subtitleContainer_23ltk_23 {
    height: 5rem;
  }
}

@media (min-width: 768px) {
  ._card_23ltk_1 ._cardBody_23ltk_20 ._subtitleContainer_23ltk_23 {
    height: 3rem;
  }
}

._card_23ltk_1
  ._cardBody_23ltk_20
  ._subtitleContainer_23ltk_23
  ._subtitle_23ltk_23 {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700;
  color: var(--primary-700, #533fbe);
}

._card_23ltk_1
  ._cardBody_23ltk_20
  ._subtitleContainerSubjectMoreVisible_23ltk_31 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

._card_23ltk_1
  ._cardBody_23ltk_20
  ._subtitleContainerSubjectMoreVisible_23ltk_31
  ._subtitle_23ltk_23 {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700;
  color: var(--primary-700, #533fbe);
}

._card_23ltk_1 ._cardBody_23ltk_20 ._title_23ltk_39 {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
  color: var(--gray-900, #111827);
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

._card_23ltk_1 ._cardBody_23ltk_20 ._titleSubjectMoreVisible_23ltk_43 {
  margin-bottom: 0.5rem;
  height: 4rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.25rem;
  color: var(--gray-900, #111827);
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

._card_23ltk_1 ._cardBody_23ltk_20 ._titleSpreaded_23ltk_47 {
  margin-bottom: 0.5rem;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.25rem;
  color: var(--gray-900, #111827);
  -webkit-line-clamp: unset;
}

._card_23ltk_1 ._cardBody_23ltk_20 ._descriptionClosed_23ltk_51 {
  position: relative;
  height: 2rem;
  overflow: hidden;
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--gray-600, #4b5563);
}

._card_23ltk_1
  ._cardBody_23ltk_20
  ._descriptionClosed_23ltk_51
  ._descriptionOverlay_23ltk_54 {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 10;
  border-bottom-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
  --tw-gradient-from: #fff;
  --tw-gradient-to: rgb(255 255 255 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: transparent;
}

._card_23ltk_1 ._cardBody_23ltk_20 ._descriptionOpened_23ltk_59 {
  height: auto;
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--gray-600, #4b5563);
}

._card_23ltk_1 ._cardBody_23ltk_20 ._readMoreBtn_23ltk_63 {
  margin-top: 0.5rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700;
  color: var(--primary-700, #533fbe);
}

._card_23ltk_1 ._cardBody_23ltk_20 ._cardCta_23ltk_67 {
  margin-top: 0.5rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  background-color: var(--primary-50, #eeecf9);
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--gray-900, #111827);
}

._card_23ltk_1 ._cardBody_23ltk_20 ._cardCta_23ltk_67:hover {
  background-color: var(--primary-100, #ddd9f2);
}

._card_23ltk_1 ._cardBody_23ltk_20 ._cardCta_23ltk_67 p {
  margin-right: 1rem;
  margin-top: 0.125rem;
}

._card_23ltk_1 ._cardBody_23ltk_20 ._cardCta_23ltk_67:disabled {
  cursor: not-allowed;
  background-color: var(--gray-200, #e5e7eb);
  color: var(--gray-400, #9ca3af);
}

._card_23ltk_1 ._cardBody_23ltk_20 button._cardBtnCta_23ltk_80 {
  margin-top: 0.5rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  background-color: var(--secondary-700, #d0021b);
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.75rem;
  line-height: 1rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

._card_23ltk_1 ._cardBody_23ltk_20 button._cardBtnCta_23ltk_80:hover {
  background-color: var(--secondary-800, #a60216);
}

._card_23ltk_1 ._cardBody_23ltk_20 ._selectedCardCta_23ltk_84 {
  background-color: var(--primary-50, #eeecf9);
}

._RecommenderExitStrategy_rorzr_1 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

@media (min-width: 1024px) {
  ._RecommenderExitStrategy_rorzr_1 {
    display: flex;
    flex-direction: row;
  }
}

._RecommenderExitStrategy_rorzr_1 h2 {
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: 700;
}

._RecommenderExitStrategy_rorzr_1 h3 {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 600;
}

._RecommenderExitStrategy_rorzr_1 h4 {
  font-weight: 600;
}

._RecommenderExitStrategy_rorzr_1 h5 {
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

._RecommenderExitStrategy_rorzr_1 > button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  height: 1.5rem;
  width: 1.5rem;
}

._RecommenderExitStrategy_rorzr_1 ._leftColumn_rorzr_26 {
  display: flex;
  max-height: -moz-fit-content;
  max-height: fit-content;
  width: 100%;
  flex-direction: column;
  padding: 1rem 1.5rem 1.5rem;
}

@media (min-width: 1024px) {
  ._RecommenderExitStrategy_rorzr_1 ._leftColumn_rorzr_26 {
    width: 40%;
    padding-top: 2.5rem;
  }
}

._RecommenderExitStrategy_rorzr_1
  ._leftColumn_rorzr_26
  ._mobilePersonalization_rorzr_30 {
  display: block;
}

@media (min-width: 1024px) {
  ._RecommenderExitStrategy_rorzr_1
    ._leftColumn_rorzr_26
    ._mobilePersonalization_rorzr_30 {
    display: none;
  }
}

._RecommenderExitStrategy_rorzr_1
  ._leftColumn_rorzr_26
  ._mobilePersonalization_rorzr_30
  ._gradient_rorzr_34 {
  --tw-gradient-from: #fff;
  --tw-gradient-to: rgb(255 255 255 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

._RecommenderExitStrategy_rorzr_1
  ._leftColumn_rorzr_26
  ._mobilePersonalization_rorzr_30
  > h2 {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}

._RecommenderExitStrategy_rorzr_1
  ._leftColumn_rorzr_26
  ._mobilePersonalization_rorzr_30
  > p {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
}

._RecommenderExitStrategy_rorzr_1 ._leftColumn_rorzr_26 a {
  display: flex;
  flex-direction: column;
  align-items: center;
}

._RecommenderExitStrategy_rorzr_1
  ._leftColumn_rorzr_26
  > section:first-of-type {
  flex: 1 1 auto;
}

._RecommenderExitStrategy_rorzr_1
  ._leftColumn_rorzr_26
  > section:first-of-type
  ._columnTitle_rorzr_54 {
  display: none;
  width: 100%;
}

._RecommenderExitStrategy_rorzr_1
  ._leftColumn_rorzr_26
  > section:first-of-type
  ._columnTitle_rorzr_54
  > :not([hidden])
  ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

._RecommenderExitStrategy_rorzr_1
  ._leftColumn_rorzr_26
  > section:first-of-type
  ._columnTitle_rorzr_54 {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
}

@media (min-width: 1024px) {
  ._RecommenderExitStrategy_rorzr_1
    ._leftColumn_rorzr_26
    > section:first-of-type
    ._columnTitle_rorzr_54 {
    display: flex;
  }
}

._RecommenderExitStrategy_rorzr_1
  ._leftColumn_rorzr_26
  > section:first-of-type
  > p {
  position: relative;
  display: none;
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--primary-700, #533fbe);
}

@media (min-width: 1024px) {
  ._RecommenderExitStrategy_rorzr_1
    ._leftColumn_rorzr_26
    > section:first-of-type
    > p {
    display: block;
  }
}

._RecommenderExitStrategy_rorzr_1
  ._leftColumn_rorzr_26
  > section:first-of-type
  ._dateCard_rorzr_62 {
  margin-top: 1.5rem;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  border-radius: 0.375rem;
  background-color: var(--primary-50, #eeecf9);
  padding: 1rem;
}

._RecommenderExitStrategy_rorzr_1
  ._leftColumn_rorzr_26
  > section:first-of-type
  ._dateCard_rorzr_62
  > span {
  display: flex;
  width: 50%;
  flex-wrap: nowrap;
}

._RecommenderExitStrategy_rorzr_1
  ._leftColumn_rorzr_26
  > section:first-of-type
  ._dateCard_rorzr_62
  > span
  > :not([hidden])
  ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

._RecommenderExitStrategy_rorzr_1
  ._leftColumn_rorzr_26
  > section:first-of-type
  ._dateCard_rorzr_62
  > span
  > p {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 0.75rem;
  line-height: 1rem;
}

._RecommenderExitStrategy_rorzr_1 ._leftColumn_rorzr_26 ._nextSteps_rorzr_77 {
  margin-top: auto;
  display: none;
  width: 100%;
  flex-direction: column;
}

@media (min-width: 1024px) {
  ._RecommenderExitStrategy_rorzr_1 ._leftColumn_rorzr_26 ._nextSteps_rorzr_77 {
    display: flex;
  }
}

._RecommenderExitStrategy_rorzr_1
  ._leftColumn_rorzr_26
  ._nextSteps_rorzr_77
  p._preampNextSteps_rorzr_81 {
  margin-top: 1.75rem;
}

._RecommenderExitStrategy_rorzr_1 ._rightColumn_rorzr_87 {
  height: 100%;
  width: 100%;
  border-top-width: 1px;
  border-color: var(--gray-300, #d1d5db);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

@media (min-width: 1024px) {
  ._RecommenderExitStrategy_rorzr_1 ._rightColumn_rorzr_87 {
    border-top-width: 0px;
    background-color: var(--primary-50, #eeecf9);
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
}

._RecommenderExitStrategy_rorzr_1
  ._rightColumn_rorzr_87
  ._contactContainer_rorzr_89
  ._gradient_rorzr_34 {
  --tw-gradient-from: var(--primary-50, #eeecf9);
  --tw-gradient-to: rgb(255 255 255 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

._RecommenderExitStrategy_rorzr_1
  ._rightColumn_rorzr_87
  ._contactContainer_rorzr_89 {
  display: none;
  flex-direction: column;
  align-items: flex-start;
}

._RecommenderExitStrategy_rorzr_1
  ._rightColumn_rorzr_87
  ._contactContainer_rorzr_89
  > :not([hidden])
  ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

._RecommenderExitStrategy_rorzr_1
  ._rightColumn_rorzr_87
  ._contactContainer_rorzr_89 {
  padding-bottom: 1rem;
}

@media (min-width: 1024px) {
  ._RecommenderExitStrategy_rorzr_1
    ._rightColumn_rorzr_87
    ._contactContainer_rorzr_89 {
    visibility: visible;
    display: flex;
  }
}

._RecommenderExitStrategy_rorzr_1
  ._rightColumn_rorzr_87
  ._contactContainer_rorzr_89
  > p {
  font-size: 0.75rem;
  line-height: 1.25rem;
}

._RecommenderExitStrategy_rorzr_1
  ._rightColumn_rorzr_87
  ._contactContainer_rorzr_89
  > p
  b {
  color: var(--primary-700, #533fbe);
}

._RecommenderExitStrategy_rorzr_1 ._rightColumn_rorzr_87 ._divider_rorzr_106 {
  display: none;
  border-color: var(--primary-700, #533fbe);
  opacity: 0.5;
}

@media (min-width: 1024px) {
  ._RecommenderExitStrategy_rorzr_1 ._rightColumn_rorzr_87 ._divider_rorzr_106 {
    display: flex;
  }
}

._RecommenderExitStrategy_rorzr_1 ._rightColumn_rorzr_87 > h3 {
  margin-top: 1rem;
}

._RecommenderExitStrategy_rorzr_1
  ._rightColumn_rorzr_87
  ._whatsNextCard_rorzr_114 {
  margin-top: 0.75rem;
  width: 100%;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 1rem;
}

._RecommenderExitStrategy_rorzr_1
  ._rightColumn_rorzr_87
  ._whatsNextCard_rorzr_114
  > div {
  display: flex;
}

._RecommenderExitStrategy_rorzr_1
  ._rightColumn_rorzr_87
  ._whatsNextCard_rorzr_114
  > div
  > :not([hidden])
  ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

._RecommenderExitStrategy_rorzr_1
  ._rightColumn_rorzr_87
  ._whatsNextCard_rorzr_114
  > div
  > h4 {
  color: var(--primary-700, #533fbe);
}

._RecommenderExitStrategy_rorzr_1
  ._rightColumn_rorzr_87
  ._whatsNextCard_rorzr_114
  > ul {
  margin: 1rem 1.5rem;
  list-style-type: disc;
  font-size: 0.75rem;
  line-height: 1.25rem;
}

._RecommenderExitStrategy_rorzr_1 ._rightColumn_rorzr_87 ._nextSteps_rorzr_77 {
  margin-bottom: 0.5rem;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}

._RecommenderExitStrategy_rorzr_1
  ._rightColumn_rorzr_87
  ._nextSteps_rorzr_77
  > :not([hidden])
  ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

@media (min-width: 1024px) {
  ._RecommenderExitStrategy_rorzr_1
    ._rightColumn_rorzr_87
    ._nextSteps_rorzr_77 {
    display: none;
  }
}

._RecommenderExitStrategy_rorzr_1 ._icon_rorzr_137 {
  margin-top: auto;
  margin-bottom: auto;
  font-weight: 700;
  color: var(--primary-700, #533fbe);
}

._RecommenderExitStrategy_rorzr_1 ._compactSchoolCard_rorzr_141 {
  margin-top: 1.5rem;
}

._RecommenderExitStrategy_rorzr_1 ._stickyActions_rorzr_145 {
  position: sticky;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top-width: 1px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 1.5rem;
}

@media (min-width: 1024px) {
  ._RecommenderExitStrategy_rorzr_1 ._stickyActions_rorzr_145 {
    display: none;
  }
}

._RecommenderExitStrategy_rorzr_1 button._applicationCta_rorzr_150 {
  width: 100% !important;
  border-radius: 9999px !important;
  border-width: 1px !important;
  border-color: var(--secondary-700, #d0021b) !important;
  background-color: var(--secondary-700, #d0021b) !important;
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

._RecommenderExitStrategy_rorzr_1 button._continueToResults_rorzr_155 {
  width: 100%;
  border-radius: 9999px;
  border-width: 1px;
  border-color: var(--primary-800, #1d5aa2);
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: var(--primary-800, #1d5aa2);
}

._RecommenderExitStrategy_rorzr_1 ._nextSteps_rorzr_77 {
  width: 100%;
}

._RecommenderExitStrategy_rorzr_1
  ._nextSteps_rorzr_77
  > :not([hidden])
  ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

._RecommenderExitStrategy_rorzr_1 ._nextSteps_rorzr_77 > div {
  display: flex;
  flex-direction: column;
}

._RecommenderExitStrategy_rorzr_1
  ._nextSteps_rorzr_77
  > div
  > :not([hidden])
  ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}

._RecommenderExitStrategy_rorzr_1 ._nextSteps_rorzr_77 > div > p {
  text-align: center;
  font-size: 9px;
}

._RecommenderExitStrategy_rorzr_1
  ._nextSteps_rorzr_77
  > div
  > p._preampNextSteps_rorzr_81 {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700;
}

._admissionsText_rorzr_179 {
  position: relative;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

@media (min-width: 1024px) {
  ._admissionsText_rorzr_179 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

._admissionsText_rorzr_179._admissionsCollapsible_rorzr_182 {
  margin-bottom: 0;
  max-height: 4rem;
  overflow: hidden;
}

._admissionsText_rorzr_179._expand_rorzr_186 {
  max-height: 100%;
}

._admissionsText_rorzr_179 ._gradient_rorzr_34 {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 3rem;
  width: 100%;
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

._admissionsText_rorzr_179 a {
  font-weight: 700;
  color: var(--primary-600, #3a7fd0);
  text-decoration-line: underline;
}

._expandButton_rorzr_198 {
  margin-bottom: 1rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
  color: var(--primary-700, #533fbe);
}

._submissionStatus_l02jv_1 {
  display: flex;
  height: 100%;
  min-height: 400px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  text-align: center;
  font-size: 1.5rem;
  line-height: 2rem;
}

@media (min-width: 768px) {
  ._submissionStatus_l02jv_1 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

._successIcon_l02jv_4 {
  margin-right: 0.5rem;
  margin-bottom: 1.5rem;
  color: var(--primary-500, #508dd5);
}

._errorIcon_l02jv_8 {
  margin-right: 0.5rem;
  margin-bottom: 1.5rem;
  color: var(--secondary-500, #d93549);
}

._radioField_q0d5w_1 {
  display: flex;
  -moz-column-gap: 0.5rem;
  column-gap: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

._radioField_q0d5w_1 ._radio_q0d5w_1 {
  height: 1.25rem;
  width: 1.25rem;
  border-radius: 9999px;
  border-width: 1px;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

._radioField_q0d5w_1 ._checked_q0d5w_7 {
  border-width: 5px;
  border-color: var(--primary-600, #3a7fd0);
}

._form_142m3_1 {
  display: flex;
  height: 100%;
  flex-direction: column;
}

._form_142m3_1 legend {
  display: none;
}

._form_142m3_1 .form-control {
  margin-bottom: 1rem;
  width: 100%;
  border-radius: 0.5rem;
  border-width: 1px;
  padding: 0.75rem;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

._form_142m3_1 .error-detail {
  border-radius: 0.5rem;
  padding-bottom: 0.25rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

._form_142m3_1 button._submitBtn_142m3_16 {
  margin-top: 0.5rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  background-color: var(--secondary-500, #d93549);
  padding: 0.75rem 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

._zipWidget_1xd5h_1 {
  margin-bottom: 1rem;
  width: 100%;
  border-radius: 0.5rem;
  border-width: 1px;
  padding: 0.75rem;
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

._tcpaSection_1ixh5_1 h4 {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  color: var(--gray-500, #6b7280);
}

._tcpaSection_1ixh5_1 div {
  margin-bottom: 1rem;
  font-size: 10px;
  line-height: 20px;
  line-height: 1rem;
  color: var(--gray-500, #6b7280);
}

._tcpaSection_1ixh5_1 a {
  color: var(--primary-700, #533fbe);
}

._tcpaSection_1ixh5_1 ._tcpaSecondParagraph_1ixh5_14 {
  margin-top: 0.5rem;
  font-size: 10px;
  line-height: 20px;
}

._tcpaSection_1ixh5_1 ._leadShareOptIn_1ixh5_18 {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700;
}

._tcpaSection_1ixh5_1 ._leadShareOptIn_1ixh5_18 input {
  margin-right: 0.5rem;
  height: 1rem;
  width: 1rem;
}

._recommenderPostResultForm_vs1e7_1 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1.5rem;
  background-color: var(--primary-50, #eeecf9);
  padding: 2rem 1.5rem;
}

@media (min-width: 1024px) {
  ._recommenderPostResultForm_vs1e7_1 {
    flex-direction: row;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

._recommenderPostResultForm_vs1e7_1 header {
  width: 100%;
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
}

@media (min-width: 768px) {
  ._recommenderPostResultForm_vs1e7_1 header {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

._recommenderPostResultForm_vs1e7_1 ._programInfo_vs1e7_8 {
  order: 1;
  flex: 1 1 0%;
}

@media (min-width: 1024px) {
  ._recommenderPostResultForm_vs1e7_1 ._programInfo_vs1e7_8 {
    order: 2;
  }
}

._recommenderPostResultForm_vs1e7_1 ._form_vs1e7_12 {
  order: 1;
  flex: 1 1 0%;
}

._recommenderPostResultForm_vs1e7_1 ._form_vs1e7_12 button._cancelBtn_vs1e7_15 {
  margin-top: 0.5rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  -moz-column-gap: 0.25rem;
  column-gap: 0.25rem;
  padding: 0.75rem 1.5rem;
  color: var(--primary-700, #533fbe);
}

._recommenderPostResultForm_vs1e7_1 ._tcpa_vs1e7_20 {
  order: 3;
  width: 100%;
}

._verticalLayout_vs1e7_25 {
  flex-direction: column;
}

._verticalLayout_vs1e7_25 ._programInfo_vs1e7_8 {
  order: 1;
}

._verticalLayout_vs1e7_25 ._form_vs1e7_12 {
  order: 2;
}

._infoGrid_1loqf_1 {
  margin-top: 1rem;
  display: grid;
  row-gap: 0.5rem;
  color: var(--gray-500, #6b7280);
}

@media (min-width: 768px) {
  ._infoGrid_1loqf_1 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

._infoGrid_1loqf_1 p {
  grid-column: span 5 / span 5;
  word-break: break-all;
  font-size: 1rem;
  line-height: 1.5rem;
}

@media (min-width: 768px) {
  ._infoGrid_1loqf_1 p {
    grid-column: span 4 / span 4;
    padding-left: 0.75rem;
    padding-left: 0;
  }
}

._header_1loqf_9 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
}

._header_1loqf_9 ._buttonDisabled_1loqf_13 {
  color: var(--gray-500, #6b7280);
}

._formErrorText_1loqf_17 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

._piiConfirmation_43dwd_1 {
  display: flex;
  min-height: 100%;
  width: 100%;
  flex-direction: column;
  background-color: var(--primary-50, #eeecf9);
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 2.5rem;
  padding-bottom: 0;
}

@media (min-width: 1024px) {
  ._piiConfirmation_43dwd_1 {
    padding-left: 4rem;
    padding-right: 4rem;
    padding-bottom: 2.5rem;
  }
}

._piiConfirmation_43dwd_1 ._content_43dwd_3 {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 1.5rem;
}

@media (min-width: 1024px) {
  ._piiConfirmation_43dwd_1 ._content_43dwd_3 {
    flex: 0 1 auto;
    flex-direction: row;
  }
}

._piiConfirmation_43dwd_1 ._content_43dwd_3 header h4 {
  margin-bottom: 1rem;
  text-align: center;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
}

@media (min-width: 1024px) {
  ._piiConfirmation_43dwd_1 ._content_43dwd_3 header h4 {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

._piiConfirmation_43dwd_1 ._content_43dwd_3 ._piiSection_43dwd_12 {
  flex: 0 1 auto;
}

@media (min-width: 1024px) {
  ._piiConfirmation_43dwd_1 ._content_43dwd_3 ._piiSection_43dwd_12 {
    width: 50%;
  }
}

._piiConfirmation_43dwd_1
  ._content_43dwd_3
  ._piiSection_43dwd_12
  ._panel_43dwd_15 {
  border-radius: 0.5rem;
  background-color: var(--gray-50, #f9fafb);
  padding: 1.5rem;
}

._piiConfirmation_43dwd_1
  ._content_43dwd_3
  ._piiSection_43dwd_12
  ._panel_43dwd_15
  button {
  font-weight: 700;
  color: var(--primary-700, #533fbe);
}

._piiConfirmation_43dwd_1
  ._content_43dwd_3
  ._piiSection_43dwd_12
  ._panel_43dwd_15
  input {
  margin-top: 1rem;
  display: inline-flex;
  width: 100%;
  border-radius: 0.75rem;
  border-width: 1px;
  border-color: var(--gray-100, #f3f4f6);
  padding: 1rem 1.5rem;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

._piiConfirmation_43dwd_1
  ._content_43dwd_3
  ._piiSection_43dwd_12
  ._submitInfoBtn_43dwd_27 {
  margin-top: 1.5rem;
  display: none;
  width: 100%;
  white-space: nowrap;
  border-radius: 9999px;
  background-color: var(--secondary-600, #d51b32);
  padding: 1rem 2rem;
  font-weight: 700;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

._piiConfirmation_43dwd_1
  ._content_43dwd_3
  ._piiSection_43dwd_12
  ._submitInfoBtn_43dwd_27:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

@media (min-width: 1024px) {
  ._piiConfirmation_43dwd_1
    ._content_43dwd_3
    ._piiSection_43dwd_12
    ._submitInfoBtn_43dwd_27 {
    display: block;
  }
}

._piiConfirmation_43dwd_1
  ._content_43dwd_3
  ._piiSection_43dwd_12
  ._disabledSubmitInfoBtn_43dwd_30 {
  cursor: not-allowed;
  background-color: var(--gray-400, #9ca3af);
}

._piiConfirmation_43dwd_1
  ._content_43dwd_3
  ._piiSection_43dwd_12
  ._backTopMatchesBtn_43dwd_33 {
  display: none;
}

@media (min-width: 768px) {
  ._piiConfirmation_43dwd_1
    ._content_43dwd_3
    ._piiSection_43dwd_12
    ._backTopMatchesBtn_43dwd_33 {
    display: flex;
  }
}

._piiConfirmation_43dwd_1 ._content_43dwd_3 ._selectedPrograms_43dwd_39 {
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
  row-gap: 0.5rem;
}

@media (min-width: 1024px) {
  ._piiConfirmation_43dwd_1 ._content_43dwd_3 ._selectedPrograms_43dwd_39 {
    flex: 1 1 0%;
  }
}

._piiConfirmation_43dwd_1 ._content_43dwd_3 ._tcpa_43dwd_43 {
  width: 100%;
  font-size: 0.75rem;
  line-height: 1rem;
}

._alert_43dwd_49 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  border-radius: 0.25rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(254 202 202 / var(--tw-bg-opacity));
  padding: 1.5rem;
}

._mobileCtas_43dwd_53 {
  position: sticky;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top-width: 2px;
  background-color: var(--primary-50, #eeecf9);
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
}

@media (min-width: 1024px) {
  ._mobileCtas_43dwd_53 {
    display: none;
  }
}

._mobileCtas_43dwd_53 ._submitInfoBtn_43dwd_27 {
  width: 100%;
  white-space: nowrap;
  border-radius: 0.5rem;
  background-color: var(--secondary-600, #d51b32);
  padding: 1rem 2rem;
  font-weight: 700;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

._mobileCtas_43dwd_53 ._submitInfoBtn_43dwd_27:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

._mobileCtas_43dwd_53 ._disabledSubmitInfoBtn_43dwd_30 {
  cursor: not-allowed;
  background-color: var(--gray-400, #9ca3af);
}

._mobileCtas_43dwd_53 ._ctaTitle_43dwd_61 {
  font-weight: 700;
}

._mobileCtas_43dwd_53 ._ctaSubtitle_43dwd_64 {
  margin-bottom: 1.25rem;
  font-size: 10px;
}

._backTopMatchesBtn_43dwd_33 {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

._loadingContainer_43dwd_73 {
  display: flex;
  height: 100%;
  min-height: 45vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

._loadingContainer_43dwd_73 h2 {
  text-align: center;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
}

._refinementHeading_2cf2a_1 {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
}

._refinementHeading_2cf2a_1 ._label_2cf2a_4 {
  margin-bottom: 0.5rem;
  font-weight: 700;
}

._refinementList_2cf2a_9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}

._refinementList_2cf2a_9 ._refinementLabel_2cf2a_12 {
  cursor: pointer;
}

._refinementList_2cf2a_9 input[type="checkbox"] {
  height: 0.875rem;
  width: 0.875rem;
}

._refinementList_2cf2a_9 input[type="checkbox"]:checked {
  accent-color: var(--primary-700, #533fbe);
}

._sidebar_bsi3m_1 {
  display: none;
  padding: 1rem;
}

@media (min-width: 768px) {
  ._sidebar_bsi3m_1 {
    display: block;
    max-width: 20rem;
    flex: 1 1 0%;
  }
}

._sidebar_bsi3m_1 ._category_bsi3m_4 {
  margin-bottom: 1.25rem;
  border-bottom-width: 1px;
  border-color: var(--gray-300, #d1d5db);
  padding-bottom: 1.25rem;
}

._duplicatePopup_jxoxx_1 {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 50;
  height: 100%;
  overflow: auto;
}

@media (min-width: 768px) {
  ._duplicatePopup_jxoxx_1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

._duplicatePopup_jxoxx_1 ._overlay_jxoxx_4 {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  --tw-bg-opacity: 0.25;
}

._duplicatePopup_jxoxx_1 ._card_jxoxx_8 {
  position: relative;
  display: flex;
  width: 100%;
  max-width: 32rem;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  background-color: var(--primary-100, #ddd9f2);
  padding: 2.5rem 1.5rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

._duplicatePopup_jxoxx_1 ._card_jxoxx_8 button._close_jxoxx_11 {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

._duplicatePopup_jxoxx_1 ._card_jxoxx_8 ._imgContainer_jxoxx_15 {
  margin-bottom: 0.75rem;
  display: flex;
  height: 4rem;
  width: 7rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 1rem;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

._duplicatePopup_jxoxx_1 ._card_jxoxx_8 ._imgContainer_jxoxx_15 img {
  -o-object-fit: contain;
  object-fit: contain;
}

._duplicatePopup_jxoxx_1 ._card_jxoxx_8 section {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

._duplicatePopup_jxoxx_1 ._card_jxoxx_8 section h2 {
  margin-bottom: 1rem;
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 700;
}

._duplicatePopup_jxoxx_1 ._card_jxoxx_8 section p {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

._duplicatePopup_jxoxx_1 ._card_jxoxx_8 ._otherProgramsContainer_jxoxx_35 {
  position: relative;
  width: 100%;
}

._duplicatePopup_jxoxx_1
  ._card_jxoxx_8
  ._otherProgramsContainer_jxoxx_35
  ._otherPrograms_jxoxx_35 {
  overflow: auto;
  border-radius: 0.125rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 1.5rem 1rem;
}

._duplicatePopup_jxoxx_1
  ._card_jxoxx_8
  ._otherProgramsContainer_jxoxx_35
  ._otherPrograms_jxoxx_35::-webkit-scrollbar {
  width: 0.25rem;
}

._duplicatePopup_jxoxx_1
  ._card_jxoxx_8
  ._otherProgramsContainer_jxoxx_35
  ._otherPrograms_jxoxx_35::-webkit-scrollbar-track {
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
  background-color: var(--primary-300, #a99fdf);
}

._duplicatePopup_jxoxx_1
  ._card_jxoxx_8
  ._otherProgramsContainer_jxoxx_35
  ._otherPrograms_jxoxx_35::-webkit-scrollbar-thumb {
  height: 5rem;
  background-color: var(--primary-700, #533fbe);
}

._duplicatePopup_jxoxx_1
  ._card_jxoxx_8
  ._otherProgramsContainer_jxoxx_35
  ._otherPrograms_jxoxx_35
  h4 {
  margin-bottom: 1rem;
  font-weight: 700;
}

._duplicatePopup_jxoxx_1
  ._card_jxoxx_8
  ._otherProgramsContainer_jxoxx_35
  ._otherPrograms_jxoxx_35
  ul {
  position: relative;
  display: flex;
  max-height: 10rem;
  list-style-position: outside;
  list-style-type: disc;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  text-align: left;
}

._duplicatePopup_jxoxx_1
  ._card_jxoxx_8
  ._otherProgramsContainer_jxoxx_35
  ._otherPrograms_jxoxx_35
  ul
  li {
  margin-left: 1.5rem;
  width: 100%;
}

._duplicatePopup_jxoxx_1
  ._card_jxoxx_8
  ._otherProgramsContainer_jxoxx_35
  ._otherPrograms_jxoxx_35
  ul
  li:last-child {
  padding-bottom: 2rem;
}

._duplicatePopup_jxoxx_1
  ._card_jxoxx_8
  ._otherProgramsContainer_jxoxx_35
  ._hide_jxoxx_68 {
  position: absolute;
  bottom: 0.75rem;
  left: 0px;
  right: 0.5rem;
  height: 2.5rem;
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
  --tw-gradient-from: #fff;
  --tw-gradient-to: rgb(255 255 255 / 0);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

._duplicatePopup_jxoxx_1 ._card_jxoxx_8 ._nextSteps_jxoxx_73 button {
  font-weight: 700;
  color: var(--primary-700, #533fbe);
  text-decoration-line: underline;
}

._duplicatePopup_jxoxx_1 ._card_jxoxx_8 button._keepBrowsing_jxoxx_79 {
  margin-top: 0.75rem;
  width: 100%;
  border-radius: 9999px;
  background-color: var(--secondary-500, #d93549);
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

._slideOut_tgze2_1 {
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 50;
  display: flex;
  min-height: 100vh;
  width: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes _slide-bottom_tgze2_1 {
  0% {
    transform: translateY(1000px);
  }

  to {
    transform: translateY(0);
  }
}

._slideOut_tgze2_1 {
  animation: _slide-bottom_tgze2_1 0.5s;
  overflow-y: scroll;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-top: 2rem;
  padding-bottom: 2rem;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-duration: 0.7s;
}

@media (min-width: 768px) {
  ._slideOut_tgze2_1 {
    max-width: 32rem;
  }
}

._slideOut_tgze2_1 {
  min-height: -webkit-fill-available;
}

@media (min-width: 768px) {
  @keyframes _slide-left_tgze2_1 {
    0% {
      transform: translate(500px);
    }

    to {
      transform: translate(0);
    }
  }

  ._slideOut_tgze2_1 {
    animation: _slide-left_tgze2_1 0.5s;
  }
}

._slideOut_tgze2_1 {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

._slideOut_tgze2_1::-webkit-scrollbar {
  display: none;
}

._slideOut_tgze2_1 ._content_tgze2_20 {
  position: relative;
  height: 100%;
  width: 100%;
}

._slideOut_tgze2_1 ._closeBtn_tgze2_24 {
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 0.5rem;
}

._postResultForm_460qr_1 {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

._postResultForm_460qr_1 h4 {
  margin-bottom: 1.5rem;
  display: inline-flex;
  align-items: flex-start;
  -moz-column-gap: 0.5rem;
  column-gap: 0.5rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
}

@media (min-width: 1024px) {
  ._postResultForm_460qr_1 h4 {
    align-items: center;
  }
}

._postResultForm_460qr_1 h4 ._icon_460qr_7 {
  flex-shrink: 0;
  color: var(--secondary-500, #d93549);
}

._postResultForm_460qr_1 ._form_460qr_12 {
  margin-top: 2rem;
  height: 100%;
}

._postResultForm_460qr_1 ._spacer_460qr_16 {
  flex: 1 1 0%;
}

._postResultForm_460qr_1 ._cta_460qr_20 {
  position: sticky;
  bottom: 0px;
  border-top-width: 1px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-top: 1rem;
  text-align: center;
}

._postResultForm_460qr_1 ._cta_460qr_20 b {
  margin-bottom: 0.5rem;
}

._postResultForm_460qr_1 ._cta_460qr_20 p {
  font-size: 10px;
  line-height: 20px;
}

._postResultForm_460qr_1 ._cta_460qr_20 button._submitBtn_460qr_31 {
  margin-top: 1rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  background-color: var(--secondary-500, #d93549);
  padding: 0.75rem 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

._cta_460qr_20 {
  position: sticky;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

._piiConfirmation_1a307_1 {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding-left: 2rem;
  padding-right: 2rem;
}

._piiConfirmation_1a307_1 ._piiSection_1a307_4 {
  flex: 1 1 0%;
}

._piiConfirmation_1a307_1 h2 {
  font-size: 28px;
  font-weight: 700;
}

._piiConfirmation_1a307_1 h3 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
}

._piiConfirmation_1a307_1 h4 {
  margin-top: 1.5rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
}

._piiConfirmation_1a307_1 h5 {
  font-weight: 700;
}

._piiConfirmation_1a307_1 ._title_1a307_24 {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  -moz-column-gap: 0.5rem;
  column-gap: 0.5rem;
}

._piiConfirmation_1a307_1 ._subtitle_1a307_28 {
  margin-bottom: 2rem;
  font-weight: 600;
}

._piiConfirmation_1a307_1 ._formErrorText_1a307_32 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity));
}

._piiConfirmation_1a307_1 ._infoPanel_1a307_36 {
  margin-top: 1.5rem;
  border-radius: 0.5rem;
  background-color: var(--gray-50, #f9fafb);
  padding: 1.5rem;
}

._piiConfirmation_1a307_1 ._infoPanel_1a307_36 button {
  font-weight: 700;
  color: var(--primary-700, #533fbe);
}

._piiConfirmation_1a307_1 ._infoPanel_1a307_36 ._header_1a307_43 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

._piiConfirmation_1a307_1
  ._infoPanel_1a307_36
  ._header_1a307_43
  ._buttonDisabled_1a307_46 {
  color: var(--gray-500, #6b7280);
}

._piiConfirmation_1a307_1 ._infoPanel_1a307_36 input {
  margin-top: 1rem;
  display: inline-flex;
  width: 100%;
  border-radius: 0.75rem;
  border-width: 1px;
  border-color: var(--gray-100, #f3f4f6);
  padding: 1rem 1.5rem;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color),
    0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

._piiConfirmation_1a307_1 ._tcpaSection_1a307_56 {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top-width: 1px;
  border-color: var(--gray-100, #f3f4f6);
  padding-top: 1.5rem;
  padding-bottom: 4rem;
}

._piiConfirmation_1a307_1 ._tcpaSection_1a307_56 button {
  display: none;
  border-radius: 0.5rem;
  background-color: var(--secondary-600, #d51b32);
  padding: 1rem 2rem;
  font-weight: 700;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

._piiConfirmation_1a307_1 ._tcpaSection_1a307_56 button:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

@media (min-width: 768px) {
  ._piiConfirmation_1a307_1 ._tcpaSection_1a307_56 button {
    display: inline-flex;
  }
}

._piiConfirmation_1a307_1 ._tcpaSection_1a307_56 p {
  margin-top: 1rem;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--gray-500, #6b7280);
}

._piiConfirmation_1a307_1 ._tcpaText_1a307_68 a {
  color: var(--primary-700, #533fbe);
}

._loadingContainer_1a307_73 {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

._loadingContainer_1a307_73 ._loadingText_1a307_76 {
  margin-bottom: -3.5rem;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
}

._alert_1a307_81 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  border-radius: 0.25rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(239 68 68 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(254 202 202 / var(--tw-bg-opacity));
  padding: 1.5rem;
}

._submitBtnSection_1a307_85 {
  position: sticky;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top-width: 2px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding-top: 1rem;
  text-align: center;
}

._submitBtnSection_1a307_85 ._submitInfoBtn_1a307_88 {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 9999px;
  background-color: var(--secondary-500, #d93549);
  padding: 0.75rem 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

._submitBtnSection_1a307_85 ._disabledSubmitInfoBtn_1a307_92 {
  width: 100%;
  cursor: not-allowed;
  background-color: var(--gray-400, #9ca3af);
}

._submitBtnSection_1a307_85 ._ctaTitle_1a307_96 {
  font-weight: 700;
}

._submitBtnSection_1a307_85 ._ctaSubtitle_1a307_100 {
  margin-bottom: 1.25rem;
  font-size: 10px;
}

._loadingContainer_1a307_73 {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

._loadingContainer_1a307_73 h2 {
  text-align: center;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
}

._submissionsCard_libcr_1 {
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 1.5rem;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@media (min-width: 1024px) {
  ._submissionsCard_libcr_1 {
    flex-direction: row;
    align-items: center;
  }
}

._submissionsCard_libcr_1 ._cardHeader_libcr_4 {
  display: flex;
  flex: 1 1 0%;
  align-items: center;
  gap: 1rem;
}

._submissionsCard_libcr_1 ._cardHeader_libcr_4 ._cardLogo_libcr_7 {
  position: relative;
  top: 0px;
  display: flex;
  height: 3.5rem;
  width: 8rem;
  align-items: center;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 1.5rem;
  --tw-shadow: 0px 0px 15px -3px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0px 0px 15px -3px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

._submissionsCard_libcr_1 ._cardHeader_libcr_4 ._cardLogo_libcr_7 > img {
  -o-object-fit: contain;
  object-fit: contain;
}

._submissionsCard_libcr_1 p {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity));
}

._submissionsCard_libcr_1 h4 {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
}

._submissionsCard_libcr_1 button._cta_libcr_24 {
  margin-top: 1rem;
  width: 100%;
  flex: 0 1 auto;
  border-radius: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity));
  padding: 1rem 3rem;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media (min-width: 1024px) {
  ._submissionsCard_libcr_1 button._cta_libcr_24 {
    margin-top: 0;
    width: auto;
  }
}

._submissionsSection_15wgb_1 {
  margin-top: 1.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity));
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

._submissionsSection_15wgb_1 ._content_15wgb_4 {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

@media (min-width: 640px) {
  ._submissionsSection_15wgb_1 ._content_15wgb_4 {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  ._submissionsSection_15wgb_1 ._content_15wgb_4 {
    max-width: 768px;
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

@media (min-width: 1024px) {
  ._submissionsSection_15wgb_1 ._content_15wgb_4 {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  ._submissionsSection_15wgb_1 ._content_15wgb_4 {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  ._submissionsSection_15wgb_1 ._content_15wgb_4 {
    max-width: 1536px;
  }
}

._submissionsSection_15wgb_1 ._content_15wgb_4 {
  margin-left: auto;
  margin-right: auto;
}

._submissionsSection_15wgb_1 ._header_15wgb_8 {
  display: flex;
  align-items: flex-start;
}

._submissionsSection_15wgb_1 ._header_15wgb_8 ._headerText_15wgb_11 {
  flex: 1 1 0%;
}

._submissionsSection_15wgb_1 ._header_15wgb_8 ._headerText_15wgb_11 h2 {
  margin-bottom: 0.5rem;
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: 700;
}

._submissionsSection_15wgb_1 ._cardContainer_15wgb_23 {
  margin-top: 2.5rem;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1rem;
}

@media (min-width: 1024px) {
  ._submissionsSection_15wgb_1 ._cardContainer_15wgb_23 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

._submissionsSection_15wgb_1 ._flipped_15wgb_27 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

._eduMatchContainer_11g04_2 {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

@media (min-width: 640px) {
  ._eduMatchContainer_11g04_2 {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  ._eduMatchContainer_11g04_2 {
    max-width: 768px;
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

@media (min-width: 1024px) {
  ._eduMatchContainer_11g04_2 {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  ._eduMatchContainer_11g04_2 {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  ._eduMatchContainer_11g04_2 {
    max-width: 1536px;
  }
}

._eduMatchContainer_11g04_2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

._eduMatchContainer_11g04_2 ._resultsHeader_11g04_6 {
  padding-top: 3.5rem;
  padding-bottom: 2.5rem;
}

._eduMatchContainer_11g04_2 ._resultsHeader_11g04_6 p {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 700;
  color: var(--gray-900, #111827);
}

._eduMatchContainer_11g04_2 ._resultsHeader_11g04_6 h1 {
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 700;
  color: var(--gray-900, #111827);
}

._facetsFiltersDesktop_11g04_19 {
  display: none;
  align-items: center;
  gap: 1rem;
}

@media (min-width: 768px) {
  ._facetsFiltersDesktop_11g04_19 {
    display: flex;
  }
}

._facetsFiltersDesktop_11g04_19 button:focus {
  outline-width: 0px;
}

._resultsInfo_11g04_27 {
  display: flex;
  flex: 1 1 0%;
  align-items: center;
  justify-content: flex-end;
}

._relatedPrograms_11g04_31 {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

@media (min-width: 640px) {
  ._relatedPrograms_11g04_31 {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  ._relatedPrograms_11g04_31 {
    max-width: 768px;
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

@media (min-width: 1024px) {
  ._relatedPrograms_11g04_31 {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  ._relatedPrograms_11g04_31 {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  ._relatedPrograms_11g04_31 {
    max-width: 1536px;
  }
}

._relatedLabel_11g04_35 {
  margin-top: 2rem;
  font-size: 1.125rem;
  line-height: 2rem;
  color: var(--gray-500, #6b7280);
}

._relatedLabel_11g04_35 span {
  font-weight: 600;
}

._relatedLabelMargin_11g04_43 {
  margin-top: 0;
}

._bannerSection_11g04_47 {
  padding-bottom: 12rem;
}

@media (min-width: 768px) {
  ._bannerSection_11g04_47 {
    padding-bottom: 5rem;
  }

  ._resultsContainer_11g04_51 {
    display: flex;
    align-items: flex-start;
    -moz-column-gap: 1.5rem;
    column-gap: 1.5rem;
  }
}

*,
:before,
:after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: var(--gray-200, #e5e7eb);
}

:before,
:after {
  --tw-content: "";
}

html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
  font-feature-settings: normal;
}

body {
  margin: 0;
  line-height: inherit;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp,
pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button,
select {
  text-transform: none;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  background-color: transparent;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  color: var(--gray-400, #9ca3af);
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
  color: var(--gray-400, #9ca3af);
}

button,
[role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

img,
video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*,
:before,
:after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

._container_1vxjl_1 {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

@media (min-width: 640px) {
  ._container_1vxjl_1 {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  ._container_1vxjl_1 {
    max-width: 768px;
    padding-right: 2rem;
    padding-left: 2rem;
  }
}

@media (min-width: 1024px) {
  ._container_1vxjl_1 {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  ._container_1vxjl_1 {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  ._container_1vxjl_1 {
    max-width: 1536px;
  }
}

._static_1vxjl_1 {
  position: static;
}

._mt-1_1vxjl_1 {
  margin-top: 0.25rem;
}

._block_1vxjl_1 {
  display: block;
}

._grid_1vxjl_1 {
  display: grid;
}

._hidden_1vxjl_1 {
  display: none;
}

._transform_1vxjl_1 {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skew(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

._border_1vxjl_1 {
  border-width: 1px;
}

._outline_1vxjl_1 {
  outline-style: solid;
}

._filter_1vxjl_1 {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}

.no-scroll {
  position: fixed;
}

.ais-Hits-list {
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 2rem;
  padding: 1.5rem 1rem;
}

@media (min-width: 768px) {
  .ais-Hits-list {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 1024px) {
  .ais-Hits-list {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.ais-Hits-item {
  overflow: hidden;
  border-radius: 0.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0px 10px 25px -5px rgba(0, 0, 0, 0.1),
    0px 5px 10px -5px rgba(0, 0, 0, 0.04);
  --tw-shadow-colored: 0px 10px 25px -5px var(--tw-shadow-color),
    0px 5px 10px -5px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

body {
  background-color: var(--gray-50, #f9fafb);
}

@media (min-width: 1024px) {
  body {
    margin-top: 0;
  }
}

.ais-SortBy {
  border-radius: 9999px;
  border-width: 1px;
  border-color: var(--gray-300, #d1d5db);
  background-color: var(--gray-50, #f9fafb);
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.ais-SortBy-select {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  font-size: 0.875rem;
  line-height: 1.5rem;
  color: var(--gray-500, #6b7280);
}

.ais-SortBy-select:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.ais-RefinementList-list {
  margin: 0;
  width: 100%;
  list-style-type: none;
}

.ais-RefinementList-item {
  border-bottom-width: 2px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.ais-RefinementList-item:last-child {
  border-style: none;
}

.ais-RefinementList-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ais-RefinementList-labelText {
  flex: 1 1 0%;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.75rem;
  color: var(--gray-600, #4b5563);
}

.ais-RefinementList-count {
  border-radius: 9999px;
  border-width: 1px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: var(--gray-500, #6b7280);
}

.ais-SearchBox {
  margin-bottom: 1rem;
  margin-top: 1rem;
  border-radius: 0.25rem;
  border-width: 1px;
  border-color: var(--gray-300, #d1d5db);
}

.ais-SearchBox-form {
  margin-bottom: 0;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  border-radius: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.ais-SearchBox-submit {
  padding: 1rem 0.5rem 1rem 1rem;
}

.ais-SearchBox-reset {
  display: none;
}

.ais-SearchBox-input {
  width: 100%;
  border-radius: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 1rem 1rem 1rem 0.5rem;
}

.ais-SearchBox-input:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

input:focus-visible {
  outline-color: var(--primary-500, #508dd5);
}

.ais-Pagination-list {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ais-Pagination-item {
  display: inline-flex;
  height: auto;
  width: auto;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-color: transparent;
  background-color: transparent;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
}

.ais-Pagination-item:hover {
  --tw-border-opacity: 1;
  border-color: rgb(79 70 229 / var(--tw-border-opacity));
}

.ais-Pagination-item--selected {
  border-radius: 0.25rem;
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity));
}

.ais-Pagination-link {
  height: 2rem;
  width: 2rem;
  line-height: 2rem;
  color: var(--gray-500, #6b7280);
}

.ais-Pagination-link:hover {
  color: var(--gray-700, #374151);
}

.ais-Pagination-link--selected,
.ais-Pagination-link--selected:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

:root {
  /* colors */
  --primary-900: #7e1726;
  --primary-800: #b02035;
  --primary-750: #d20b28;
  --primary-700: #e22944;
  --primary-600: #fb2e4b;
  --primary-500: #fb435d;
  --primary-400: #fc586f;
  --primary-300: #fd8293;
  --primary-200: #fdabb7;
  --primary-100: #fed5db;
  --primary-50: #ffeaed;
  --secondary-900: #001225;
  --secondary-800: #001934;
  --secondary-700: #002043;
  --secondary-600: #00234a;
  --secondary-500: #1a395c;
  --secondary-400: #334f6e;
  --secondary-300: #667b92;
  --secondary-200: #99a7b7;
  --secondary-100: #ccd3db;
  --secondary-50: #e6e9ed;
  --tertiary-900: #075c5c;
  --tertiary-800: #098180;
  --tertiary-700: #0ca6a5;
  --tertiary-600: #0db8b7;
  --tertiary-500: #25bfbe;
  --tertiary-400: #3dc6c5;
  --tertiary-300: #6ed4d4;
  --tertiary-200: #9ee3e2;
  --tertiary-100: #cff1f1;
  --tertiary-50: #e7f8f8;
  --quaternary-900: #7e5c0b;
  --quaternary-800: #b0800f;
  --quaternary-700: #e3a514;
  --quaternary-600: #fcb716;
  --quaternary-500: #fcbe2d;
  --quaternary-400: #fdc545;
  --quaternary-300: #fdd473;
  --quaternary-200: #fee2a2;
  --quaternary-100: #fef1d0;
  --quaternary-50: #fff8e8;
  --quinary-900: #804423;
  --quinary-800: #b35f31;
  --quinary-700: #e67a3f;
  --quinary-600: #ff965b;
  --quinary-500: #ff9359;
  --quinary-400: #ff9f6b;
  --quinary-300: #ffb790;
  --quinary-200: #ffcfb5;
  --quinary-100: #ffe7da;
  --quinary-50: #fff3ed;
  --accent-900: #2c1352;
  --accent-800: #3e1a72;
  --accent-700: #4f2193;
  --accent-600: #5825a3;
  --accent-500: #693bac;
  --accent-400: #7951b5;
  --accent-300: #9b7cc8;
  --accent-200: #bca8da;
  --accent-100: #ded3ed;
  --accent-50: #eee9f6;
  --gray-900: #000000;
  --gray-800: #1a1a1a;
  --gray-700: #333333;
  --gray-600: #4d4d4d;
  --gray-500: #666666;
  --gray-400: #808080;
  --gray-300: #999999;
  --gray-200: #b3b3b3;
  --gray-150: #cccccc;
  --gray-100: #e6e6e6;
  --gray-50: #f5f6f8;
  --white: #ffffff;
  --black: #000000;

  /* globals */
  --body-font-family: "Lexend Giga", "Verdana", sans-serif;
  --heading-font-family: "Lexend Deca", "Helvetica", "Arial", sans-serif;

  --body-color: var(--gray-600);
  --body-secondary-color: var(--secondary-600);
  --heading-color: var(--secondary-600);
  --link-color: var(--primary-700);
  --link-color-hover: var(--primary-800);
  --button-font-size: 12px;
  --hero-background-color: var(--primary-50);
  --pill-background-color: var(--primary-50);
  --pill-color: var(--primary-700);
}

body {
  --primary-900: #001225;
  --primary-800: #001934;
  --primary-700: #002043;
  --primary-600: #00234a;
  --primary-500: #1a395c;
  --primary-400: #334f6e;
  --primary-300: #667b92;
  --primary-200: #99a7b7;
  --primary-100: #ccd3db;
  --primary-50: #e6e9ed;
  --fill-primary-700: #002043;
  --secondary-900: #7e1726;
  --secondary-800: #b02035;
  --secondary-700: #e22944;
  --secondary-600: #fb2e4b;
  --secondary-500: #fb435d;
  --secondary-400: #fc586f;
  --secondary-300: #fd8293;
  --secondary-200: #fdabb7;
  --secondary-100: #fed5db;
  --secondary-50: #ffeaed;
  --gray-50: #f5fafc;
  --body-color: #00234a;
  --gray-300: #fd8293;
  --body-font-family: "Lexend Deca", "Helvetica", "Arial", "sans-serif";
  font-family: var(
    --body-font-family,
    "Lexend Deca",
    "Helvetica",
    "Arial",
    "sans-serif"
  );
}

.bg-primary-900 {
  background-color: var(--primary-900, #7e1726);
}
