::-webkit-scrollbar {
    display: none;
  }

  * {
    scrollbar-width: none;
  }


  .comment {
    display: flex;
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 5px;
    transition: background-color 0.5s ease-in-out; 
  }
  
  .comment:hover {
    background-color: #f2f2f2; 
    cursor: pointer;
  }